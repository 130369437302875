import React, { useState, useRef, useEffect } from 'react';
import '../../styles/ManageAskUs.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisVertical, faImage, faTimes, faReply, faRectangleXmark } from '@fortawesome/free-solid-svg-icons';
import '../../styles/Loading.css'
import { toast } from "react-toastify";
import QuestionService from '../../services/QuestionService';
import HostAndPortInfo from "../../enums/EnHostandPortInfo";

const ipAdress = HostAndPortInfo.HOSTNAME
const port = HostAndPortInfo.PORT
const protocol = HostAndPortInfo.PROTOCOL

export default function ManageAskUs() {

    const questionService = new QuestionService()

    const [responseContainers, setResponseContainers] = useState({});
    const [menuOpenId, setMenuOpenId] = useState(null); // Hangi menünün açık olduğunu izler
    const menuRefs = useRef({}); // Tüm menü referanslarını izleyen obje

    const [loading, setLoading] = useState(false); // Yüklenme durumu
    const [questions, setQuestions] = useState([]);

    const [selectedImageFiles, setSelectedImageFiles] = useState([]);
    const fileInputRef = useRef(null);

    const [refresh, setRefresh] = useState(false); // Durum değişkeni

    const defaultProfileImage = `${process.env.PUBLIC_URL}/images/user-profile.png`;


    // Boş bir yere tıklandığında menüyü kapatmak için useEffect
    useEffect(() => {
        const handleClickOutside = (event) => {
            // Eğer tıklanan alan açık olan menünün dışındaysa menüyü kapat
            if (menuOpenId !== null && menuRefs.current[menuOpenId] && !menuRefs.current[menuOpenId].contains(event.target)) {
                setMenuOpenId(null);
            }
        };

        // Document üzerindeki tıklama olaylarını dinle
        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            // Temizlik işlemi: Event listener'ı kaldır
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [menuOpenId]);

    const toggleMenu = (id) => {

        if (menuOpenId === id) {
            setMenuOpenId(null); // Aynı menüye tıklanırsa kapat
        } else {
            setMenuOpenId(id); // Farklı menüye tıklanırsa o menüyü aç
        }
    };


    const handleResponseClick = (id) => {
        setResponseContainers(prev => ({
            ...prev,
            [id]: !prev[id] // Belirtilen ID için durumu değiştir
        }));
        setMenuOpenId(null);
    };


    const handleClick = () => {
        // Butona tıklandığında dosya seçme penceresini aç
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const handleImageChange = (e) => {

        const files = Array.from(e.target.files);

        if (files.length > 0) {
            setSelectedImageFiles((prevFiles) => [...prevFiles, ...files]); // Önceki dosyaları koruyarak yeni dosyaları ekle
        }
    };

    const removeImage = (indexToRemove) => {
        setSelectedImageFiles((prevFiles) =>
            prevFiles.filter((_, index) => index !== indexToRemove)
        );
    };



    useEffect(() => {

        setLoading(true)

        questionService.getAllQuestion().then(result => {

            if (result.data.success) {
                setQuestions(result.data.data);
            }
        }).catch(error => {

            toast.error(error.response.data.data.message);
        }).finally(() => {

            setLoading(false);
        });



    }, [refresh])


    function getDaysAgo(createDate) {
        const createdDate = new Date(createDate); // Sorunun oluşturulma tarihi
        const currentDate = new Date(); // Şu anki tarih

        // Günleri sadece tarih kısmı üzerinden karşılaştır
        const createdDateOnly = new Date(createdDate.getFullYear(), createdDate.getMonth(), createdDate.getDate());
        const currentDateOnly = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());

        // Gün farkını hesapla
        const timeDiff = currentDateOnly - createdDateOnly;
        const daysDiff = Math.floor(timeDiff / (1000 * 60 * 60 * 24));

        // Gün farkını döndür (0 ise "Bugün", 1 ise "Dün", diğer durumlar için "X gün önce")
        if (daysDiff === 0) {
            return 'Bugün';
        } else if (daysDiff === 1) {
            return 'Dün';
        } else {
            return `${daysDiff} gün önce`;
        }
    }

    const handleResponseChange = (e, questionId) => {

        const updatedQuestions = questions.map((q) => {
            if (q.id === questionId) {             
                return { ...q, response: e.target.value }; // Sadece ilgili sorunun yanıtını güncelle
            }
            return q; // Diğer sorulara dokunma
        });
        setQuestions(updatedQuestions); // Güncellenmiş soruları state'e ata
    };

    const handleCheckboxChange = (e, questionId) => {

        const updatedQuestions = questions.map((question) => {
            if (question.id === questionId) {              
                return { ...question, showFaq: e.target.checked }; // showFaq değerini güncelle
            }
            return question;
        });

        setQuestions(updatedQuestions); // Güncellenmiş veriyi state'e kaydet
    };


    const handleSaveResponse = (questionId) => {

        //console.log(questionId)
        const questionToSave = questions.find(q => q.id === questionId);  

            let formData = new FormData();
            formData.append('id', questionId);
            formData.append("response", questionToSave.response);
            formData.append("showFaq", questionToSave.showFaq);
    
            // Her bir dosyayı ayrı ayrı ekler
            selectedImageFiles.forEach(file => {
                formData.append('files', file);
            });
    
            questionService.updateQuestion(questionId,formData).then(result => {
    
                if (result.data.success === true) {
    
                    toast.success("Yanıt başarıyla kaydedildi.");

                    setRefresh(prev => !prev); // refresh durumunu değiştir
                    setSelectedImageFiles([]);
                    
                } else {
                    toast.error("Yanıt kaydedilemedi.");
                }
            }).catch(error => {
    
                toast.error(error.response.data.data.message);
    
            })
    
    };

    const handleDelete = (id) => {

        questionService.deleteQuestionByAdmin(id).then(result => {
    
          if (result.status === 200) {
    
            toast.success("Kullanıcı sorusu başarıyla silindi.");
            setRefresh(prev => !prev); // refresh durumunu değiştir
    
          }
    
        }).catch(error => {
    
          const fullMessage = error.response.data.data.message;
          const match = fullMessage.match(/:\s*(.*)/); // ":" karakterinden sonra gelen kısmı yakala
          const message = match ? match[1] : fullMessage; // Eğer bir eşleşme varsa al, yoksa tüm mesajı kullan
          toast.error(message);
    
        }).finally(() => {
          // Menüyü kapat
          setMenuOpenId(null); // Menüyü kapat
        })
      }

      //console.log(questions)
    return (
        <div className='admin-ask-us-container'>

            <div className="manage-file-first" >
                <p className="manage-file-first-text">Bize Sor & Cevap Yönetimi</p>
            </div>

            <div className='admin-questions-container'>

                {loading === true &&
                    <div className="loading-container">
                        <div className="loading-icon"></div> {/* Loading ikonu */}
                        <p>Veriler yükleniyor...</p>
                    </div>
                }

                {loading === false && (

                    questions.map((question, index) => (
                        <div className='manage-questions-container' key={index}>
                            <div className='manage-questions-top'>
                                <div className='manage-questions-profile'>
                                    <img
                                        src={defaultProfileImage} 
                                        alt="Profile" />

                                    <ul>
                                        <li> {question.userInfo.firstName + " " + question.userInfo.lastName + "-"  + question.userInfo.institutionName +" " + question.userInfo.institutionCity +"/" + question.userInfo.institutionDistrict}</li>
                                        <li>{getDaysAgo(question.createDate)}</li>

                                    </ul>
                                </div>

                                <div className="manage-questions-menu-container" ref={el => menuRefs.current[question.id] = el}>
                                    {!responseContainers[question.id] ? (
                                        <FontAwesomeIcon
                                            icon={faEllipsisVertical}
                                            className="admin-question-menu-icon"
                                            onClick={() => toggleMenu(question.id)} // Her soru için farklı ID
                                        />
                                    ) : (
                                        <FontAwesomeIcon
                                            style={{ color: "red" }}
                                            icon={faRectangleXmark} // Kapama ikonunu göster
                                            className="admin-question-menu-icon"
                                            onClick={() => handleResponseClick(question.id)}

                                        />
                                    )}
                                    {menuOpenId === question.id && (
                                        <div className="manage-questions-dropdown-menu">
                                            <ul>
                                                <li onClick={() => handleDelete(question.id)}>Sil</li>
                                                <li onClick={() => handleResponseClick(question.id)}>Görüntüle & Yanıtla</li>
                                            </ul>
                                        </div>
                                    )}
                                </div>

                            </div>

                            {!responseContainers[question.id] && (

                                <><div className='manage-questions-mid'>

                                    <p>{question.title}</p>
                                    <p> {question.description}</p>
                                </div></>
                            )}

                            {responseContainers[question.id] && (
                                <>
                                    <div className='manage-question-detail'>

                                        <p>{question.title}</p>
                                        <p>{question.description}</p>
                                    </div>

                                    <div className='manage-question-detail-imgs'>
                                        {question.questionImagePaths.map((imagePath, index) => (
                                            <img key={index} src={`${protocol}://${ipAdress}:${port}${imagePath}`} alt="question image" />
                                        ))}
                                    </div>



                                    <div className='reply-question-container'>

                                        {/* Response alanı: Kullanıcı yanıtını burada günceller */}

                                        <textarea
                                            type="text"
                                            value={question.response || ''} // Eğer bir yanıt varsa göster, yoksa boş göster
                                            placeholder="Yanıtınızı yazınız..."
                                            className='reply-body-input'
                                            onChange={(e) => handleResponseChange(e, question.id)} // Yanıtı güncelle

                                        />

                                        <div className="manage-faq" style={{ display: "flex" }}>
                                            {/* Checkbox ile birlikte p etiketi */}
                                            <input
                                                type="checkbox"
                                                className="form-check-input"
                                                checked={question.showFaq}
                                                onChange={(e) => handleCheckboxChange(e, question.id)} // Checkbox değiştiğinde handleCheckboxChange fonksiyonu çalışır
                                            />
                                            <p>
                                                Soru ve yanıtı <span style={{ textDecoration: "Underline" }}> Sık Sorulan Sorular </span>ekranında listelensin.
                                            </p>

                                        </div>

                                        <p>
                                            İhtiyaç halinde yanıtınızı görseller ile destekleyebilirsiniz.
                                        </p>


                                        {/* Seçilen dosya isimlerini ve önizlemelerini göster */}
                                        {selectedImageFiles.length > 0 && (
                                            <div className="selected-files-container">
                                                {selectedImageFiles.map((file, index) => (
                                                    <div key={index} className="image-preview-container">
                                                        <img
                                                            src={URL.createObjectURL(file)}
                                                            alt={`preview-${index}`}
                                                            className="image-preview"
                                                        />
                                                        <div className="image-info">
                                                            <span className="image-name">{file.name}</span>
                                                            <FontAwesomeIcon
                                                                icon={faTimes}
                                                                className="remove-icon"
                                                                onClick={() => removeImage(index)}
                                                            />
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        )}

                                        <div className='reply-question-btn-container'>

                                            <button className="reply-img-submit-button" onClick={handleClick}>
                                                <FontAwesomeIcon icon={faImage} style={{ color: "white" }} />
                                                Görsel Ekle

                                                <input
                                                    type="file"
                                                    multiple // Çoklu dosya seçimine izin ver
                                                    accept="image/*"
                                                    onChange={handleImageChange}
                                                    ref={fileInputRef}
                                                    style={{ display: 'none' }} // Input'u gizle
                                                />
                                            </button>

                                            <button className="reply-submit-button" onClick={() => handleSaveResponse(question.id)}>
                                                YANITLA
                                                <FontAwesomeIcon icon={faReply} style={{ color: "white" }} />
                                            </button>
                                        </div>

                                    </div>

                                </>
                            )}
                        </div>
                    ))
                )}




            </div>

        </div>
    )
}
