import React, { useState, useRef, useEffect } from 'react';
import NotificationService from '../../../services/NotificationService';
import { toast } from "react-toastify";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisVertical, faTimes } from '@fortawesome/free-solid-svg-icons';
import '../../../styles/YourQuestion.css';


export default function ListNotifications() {

  const notificationService = new NotificationService();


  const [showDetail, setShowDetail] = useState(false);
  const [menuOpenId, setMenuOpenId] = useState(null); // Hangi menünün açık olduğunu izler
  const menuRefs = useRef({}); // Tüm menü referanslarını izleyen obje

  const [loading, setLoading] = useState(false); // Yüklenme durumu
  const [natifications, setNotifications] = useState([]);
  const [selectedNotification, setSelectedNotification] = useState(null); //detayını görmek istediği nesne için

  const [refresh, setRefresh] = useState(false); // Durum değişkeni

  // Boş bir yere tıklandığında menüyü kapatmak için useEffect
  useEffect(() => {
    const handleClickOutside = (event) => {
      // Eğer tıklanan alan açık olan menünün dışındaysa menüyü kapat
      if (menuOpenId !== null && menuRefs.current[menuOpenId] && !menuRefs.current[menuOpenId].contains(event.target)) {
        setMenuOpenId(null);
      }
    };

    // Document üzerindeki tıklama olaylarını dinle
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      // Temizlik işlemi: Event listener'ı kaldır
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [menuOpenId]);

  const toggleMenu = (id) => {
    if (menuOpenId === id) {
      setMenuOpenId(null); // Aynı menüye tıklanırsa kapat
    } else {
      setMenuOpenId(id); // Farklı menüye tıklanırsa o menüyü aç
    }
  };

  useEffect(() => {

    setLoading(true)

    notificationService.getAllNotificationsByDateDesc().then(result => {

      if (result.data.success) {
        setNotifications(result.data.data);
      }
    }).catch(error => {

      // toast.error(error.response.data.data.message);
    }).finally(() => {

      setLoading(false);
    });
  }, [refresh])

  function getDaysAgo(createDate) {
    const createdDate = new Date(createDate); // Sorunun oluşturulma tarihi
    const currentDate = new Date(); // Şu anki tarih

    // Günleri sadece tarih kısmı üzerinden karşılaştır
    const createdDateOnly = new Date(createdDate.getFullYear(), createdDate.getMonth(), createdDate.getDate());
    const currentDateOnly = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());

    // Gün farkını hesapla
    const timeDiff = currentDateOnly - createdDateOnly;
    const daysDiff = Math.floor(timeDiff / (1000 * 60 * 60 * 24));

    // Gün farkını döndür (0 ise "Bugün", 1 ise "Dün", diğer durumlar için "X gün önce")
    if (daysDiff === 0) {
      return 'Bugün';
    } else if (daysDiff === 1) {
      return 'Dün';
    } else {
      return `${daysDiff} gün önce`;
    }
  }

  const handleDetailClick = (notification) => {
    setShowDetail(!showDetail)
    setSelectedNotification(notification); //deatylı gormek istediği nesne  set edilir
  };


  const handleReturnDetailClick = () => {
    setShowDetail(!showDetail)
    setSelectedNotification(null); //deatylı gormek istediği nesne  set edilir
  };

  const handleDelete = (id) => {

    notificationService.deleteNotification(id).then(result => {

      if (result.status === 200) {

        toast.success("Bildirim başarıyla silindi.");
        setRefresh(prev => !prev); // refresh durumunu değiştir

      }

    }).catch(error => {

      const fullMessage = error.response.data.data.message;
      const match = fullMessage.match(/:\s*(.*)/); // ":" karakterinden sonra gelen kısmı yakala
      const message = match ? match[1] : fullMessage; // Eğer bir eşleşme varsa al, yoksa tüm mesajı kullan
      toast.error(message);

    }).finally(() => {
      // Menüyü kapat
      setMenuOpenId(null); // Menüyü kapat
    })
  }

  const handleDownload = (fileName) => {

    notificationService.downloadFile(fileName).then(result => {

      //console.log(result.data)
      // Blob URL oluşturma
      const url = window.URL.createObjectURL(new Blob([result.data]));
      const link = document.createElement('a');
      link.href = url;

      // İndirilecek dosyanın adını belirtin
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      toast.success("Dosya başarıyla indirildi.");

    }).catch(error => {
      //console.error(error);
      toast.success("Dosya indirirken bir hata meydana geldi. Hata : " + error);

    })

  };

  return (
    <div className='your-question-container'>

      {loading === true &&
        <div className="loading-container">
          <div className="loading-icon"></div> {/* Loading ikonu */}
          <p>Veriler yükleniyor...</p>
        </div>
      }

      {showDetail === false && loading === false && (

        natifications.map((notification, index) => (
          <div className='your-question-user-container' key={index}>
            <div className='user-question-top'>
              <div className='user-question-profile'>

                <i class="fa-regular fa-bell notification-icon"></i>
                {getDaysAgo(notification.createDate)}
              </div>

              <div className="question-menu-container" ref={el => menuRefs.current[notification.id] = el}>
                <FontAwesomeIcon
                  icon={faEllipsisVertical}
                  className="question-menu-icon"
                  onClick={() => toggleMenu(notification.id)} // Her soru için farklı ID
                />
                {menuOpenId === notification.id && (
                  <div className="question-dropdown-menu">
                    <ul>
                      <li onClick={() => handleDelete(notification.id)}>Sil</li>
                    </ul>
                  </div>
                )}
              </div>

            </div>

            <div className='user-question-mid'>
              <p>{notification.title}</p>
              <p>{notification.content}</p>
            </div>

            <div className='user-question-bottom'>

              <button onClick={() => handleDetailClick(notification)}>
                Bildirimi Gör
              </button>

            </div>

          </div>
        ))
      )}

      {showDetail === true && selectedNotification && (
        <>
          <div className='your-question-user-container' style={{ width: "100%" }}>
            <div className='user-question-detail'>

              <p>{selectedNotification.title}</p>
              <p>{selectedNotification.content}</p>

              {selectedNotification.link && (
                <div className="selected-files-container" style={{ justifyContent: "start" }}>
                  <span className="image-name">🔗 <a
                    href={selectedNotification.link}
                    className="notification-link-text"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {selectedNotification.link}
                  </a>
                  </span>

                </div>
              )}

              {selectedNotification.fileName && (
                <div className="selected-files-container" style={{ justifyContent: "start", marginTop: "1%" }}>
                  <span style={{ cursor: "pointer" }}
                    onClick={() => handleDownload(selectedNotification.fileName)}
                    className="image-name">📁
                    <a className="notification-link-text" rel="noopener noreferrer" >  {selectedNotification.fileName} </a>

                  </span>

                </div>
              )}
            </div>

          </div>



          <button id='return-question-button' onClick={() => handleReturnDetailClick()}>
            Bildirimi Kapat
          </button>
        </>
      )}


    </div>
  )
}
