import React, { useState, useEffect } from 'react';
import { toast } from "react-toastify";
import '../../styles/ManagePresentation.css';
import '../../styles/Loading.css';
import PresentationFileService from '../../services/PresentationFileService';

export default function ManagePresentation() {

    const presentationFileService = new PresentationFileService()
    const [loading, setLoading] = useState(false); // Yüklenme durumu

    const [data, setData] = useState([]);
    const [expandedFolders, setExpandedFolders] = useState([]);

    // Veriyi yükleme fonksiyonu
    const fetchData = () => {
        setLoading(true);
        presentationFileService.getAllPresentationFiles()
            .then(result => {
             
                if (result.data.success) {
                    setData(result.data.data);
                }
            })
            .catch(error => {
                toast.error(error.response.data.data.message);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    useEffect(() => {

        fetchData();

    }, [])

    // Klasörü açma/kapama fonksiyonu
    const toggleFolder = (folderName) => {
        setExpandedFolders((prev) =>
            prev.includes(folderName)
                ? prev.filter(name => name !== folderName) // Klasörü kapat
                : [...prev, folderName] // Klasörü aç
        );
    };

    const handleDownload = (userId, fileName) => {

        presentationFileService.downloadPresentationFile(userId, fileName).then(result => {


            //console.log(result.data)
            // Blob URL oluşturma
            const url = window.URL.createObjectURL(new Blob([result.data]));
            const link = document.createElement('a');
            link.href = url;

            // İndirilecek dosyanın adını belirtin
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

            toast.success("Dosya başarıyla indirildi.");

        }).catch(error => {
            //console.error(error);
            toast.success("Dosya indirirken bir hata meydana geldi. Hata : " + error);

        })

    };

    const handleDelete = (userId, fileName) => {

        presentationFileService.deletPresentationFile(userId, fileName).then(result => {
            //console.log(result)

            if (result.data.success === true) {

                toast.success(result.data.data);
                fetchData(); // Dosya silindikten sonra verileri tekrar yükle

            } else {
                toast.error(result.data.data);
            }


        }).catch(error => {
            console.error(error);
            toast.success("Dosya silerken bir hata meydana geldi. Hata : " + error);

        })

    }


    return (
        <div className="manage-file-container">

            <div className="manage-file-first" >
                <p className="manage-file-first-text">Yarışmacı Sunumları</p>
            </div>

            <div className="file-explorer-container">


                {loading === true &&
                    <div className="loading-container">
                        <div className="loading-icon"></div> {/* Loading ikonu */}
                        <p>Veriler yükleniyor...</p>
                    </div>
                }
                {loading === false &&
                    <ul className="folder-list">
                        {data.length === 0 ? (
                            <li className="file-item empty">Listelenecek dosya bulunamadı.</li>
                        ) :  data.map((item, index) => (
                                <li key={index} className="folder-item">
                                    <div className="folder-header" onClick={() => toggleFolder(item.fileNodeResponse.name)}>
                                        <span className="folder-name">
                                            {expandedFolders.includes(item.fileNodeResponse.name) ? '📂' : '📁'} {item.fileNodeResponse.name}
                                        </span>
                                        <span className="user-info">
                                            {item.user.firstName} {item.user.lastName} - { item.user.institutionCity + " / " + item.user.institutionDistrict + " " + item.user.institutionName } 
                                        </span>
                                    </div>

                                    {expandedFolders.includes(item.fileNodeResponse.name) && (
                                        <ul className="file-list">
                                            {item.fileNodeResponse.children && item.fileNodeResponse.children.length > 0 ? (
                                                item.fileNodeResponse.children.map((child, childIndex) => (
                                                    <li key={childIndex} className="file-item">
                                                        {child.isDirectory ? '📁' : '📑'} {child.name}

                                                        {!child.isDirectory && (
                                                            <span className="file-actions">
                                                                <span
                                                                    className="icon download-icon"
                                                                    title="İndir"
                                                                    onClick={() => handleDownload(item.fileNodeResponse.name, child.name)}
                                                                >
                                                                    📥
                                                                </span>
                                                                <span
                                                                    className="icon delete-icon"
                                                                    title="Sil"
                                                                    onClick={() => handleDelete(item.fileNodeResponse.name, child.name)}
                                                                >
                                                                    🗑️
                                                                </span>
                                                            </span>
                                                        )}
                                                    </li>
                                                ))
                                            ) : (
                                                <li className="file-item empty">Bu klasör boş.</li>
                                            )}
                                        </ul>
                                    )}
                                </li>
                            ))}
                    </ul>
                }
            </div>
        </div>

    );

};

