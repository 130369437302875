import React, { useState, useEffect } from 'react'
import '../../styles/DarkTable.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Modal, Button, Form } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import UsersService from '../../services/UsersService';
import { toast } from "react-toastify";
import * as XLSX from "xlsx";

export default function ListAllUsers() {

    const navigate = useNavigate();
    const usersService = new UsersService();

    const [refreshData, setRefreshData] = useState(false);

    const [showUpdateModal, setShowUpdateModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showInfoModal, setShowInfoModal] = useState(false);
    const [selectedUserId, setSelectedUserId] = useState(null);

    const [showAddModal, setShowAddModal] = useState(false);
    const [addFormData, setAddFormData] = useState({
        name: '',
        dutyLocation: '',
        dutyDescription: ''
    });

    const [updateFormData, setUpdateFormData] = useState({
        name: '',
        dutyLocation: '',
        dutyDescription: ''
    });

    const [users, setUsers] = useState([]);

    const [infoFormData, setInfoFormData] = useState({
        id: '',
        firstName: '',
        lastName: '',
        birthdate: '',
        email: '',
        city: '',
        district: '',
        institution: ''
    });

    const [searchTerm, setSearchTerm] = useState("");

    // Arama terimine göre filtreleme
    const filteredUsers = users.filter((user) =>
        `${user.firstName} ${user.lastName}`.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const handleUpdate = (id) => {
        // Güncelleme modalını açma

        const selectedUser = users.find(User => User.id === id);

        setSelectedUserId(id);
        setUpdateFormData({
            name: selectedUser.name,
            dutyLocation: selectedUser.dutyLocation,
            dutyDescription: selectedUser.dutyDescription
        });

        setShowUpdateModal(true);
    };


    const handleCloseUpdateModal = () => {
        // Güncelleme modalını kapama

        setShowUpdateModal(false);
        setSelectedUserId(null);
        setUpdateFormData({ name: '', dutyLocation: '', dutyDescription: '' });
    };


    const handleDelete = (id) => {
        // Silme modalını açma

        setSelectedUserId(id);
        setShowDeleteModal(true);
    };


    const handleCloseDeleteModal = () => {
        // Silme modalını kapama

        setShowDeleteModal(false);
        setSelectedUserId(null);
    };


    const confirmDelete = () => {
        // Silme işlemi

        usersService.deleteAllUserRecords(selectedUserId).then(result => {

            //console.log(result)
            if (result.status === 200) {
                toast.success("Üye silindi.");
                setRefreshData(prev => !prev);
            }

        }).catch(error => {
            toast.error("Üye silinirken bir hata oluştu!");
        })

        setShowDeleteModal(false);
    };


    const updateHandleInputChange = (e) => {
        // Form verilerini güncelleme

        const { name, value } = e.target;
        setUpdateFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };


    const updateHandleSave = () => {
        // Formu kaydetme

        //console.log('Güncellenen veriler:', updateFormData);

        // organizationService.updateOrganizationUser(selectedUserId, updateFormData).then(result => {

        //     if (result.data.success) {
        //         toast.success("Veriler güncellendi.")
        //         setRefreshData(prev => !prev);
        //     }

        // }).catch(error => {
        //     toast.error("Veriler güncellenirken bir hata oluştu!");
        // })

        setShowUpdateModal(false);
    };

    const handleAdd = () => {
        // Add Data modalını açma
        setShowAddModal(true);
    };

    const handleCloseAddModal = () => {
        // Add Data modalını kapama
        setShowAddModal(false);
        setAddFormData({ name: '', dutyLocation: '', dutyDescription: '' });
    };


    const addHandleInputChange = (e) => {
        // Form verilerini güncelleme
        const { name, value } = e.target;
        setAddFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };


    const addHandleSave = () => {

        // organizationService.saveOrganizationUser(addFormData).then(result => {

        //     if (result.data.success) {
        //         toast.success("Veriler kaydedildi.")
        //         setRefreshData(prev => !prev);
        //         setAddFormData({ name: '', dutyLocation: '', dutyDescription: '' });
        //     }

        // }).catch(error => {
        //     toast.error("Veriler kaydedilirken bir hata oluştu!");
        // })

        setShowAddModal(false);
    };

    const handleInfo = (id) => {
        // info modalını açma

        setSelectedUserId(id);

        const selectedUser = users.find((user) => user.id === id);

        setInfoFormData({
            id: selectedUser.id,
            firstName: selectedUser.firstName,
            lastName: selectedUser.lastName,
            birthdate: selectedUser.birthdate,
            email: selectedUser.email,
            city: selectedUser.institution.city,
            district: selectedUser.institution.district,
            institution: selectedUser.institution.name
        });

        setShowInfoModal(true);
    };

    const handleCloseInfoModal = () => {
        // Info modalını kapama

        setShowInfoModal(false);
        setSelectedUserId(null);
        setInfoFormData({
            id: '',
            firstName: '',
            lastName: '',
            birthdate: '',
            email: '',
            city: '',
            district: '',
            institution: ''
        });
    };

    useEffect(() => {

        usersService.getAllUsers().then(result => {

            //console.log(result)
            if (result.data.success) {

                setUsers(result.data.data);
            }

        }).catch(error => {
            toast.error("Organizasyon yürütme kurulunu listelerken bir hata oluştu!");
        })

    }, [refreshData]);

    const handleExportToExcel = () => {
       
        const excelData = users
        .filter((user) => user.city.name !== "İSTANBUL")
        .map((user, index) => ({
            "Sıra": index + 1,
            "Adı Soyadı": `${user.firstName} ${user.lastName}`,
            "Okulu": user.institution.name,
            "Şehir": user.city.name,
            "İlçe": user.institution.district
        }));
    
        // Yeni bir çalışma kitabı (Excel sayfası) oluşturun
        const worksheet = XLSX.utils.json_to_sheet(excelData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "AZTY Kayıtlı Kullanıcılar");
    
        // Excel dosyasını indirin
        XLSX.writeFile(workbook, "AZTY_Users.xlsx");
    };

    return (
        <div className="dark-tb-main">
            <div className="dark-tb-container text-light">
                <table className="table table-dark table-bordered mt-3">
                    <thead>
                        <tr>
                            <th className="dark-bg-color tb-header-text" scope="col" colSpan="6">Kayıtlı Kullanıcılar</th>
                        </tr>
                        <tr>
                            <th className="dark-bg-color" scope="col" colSpan="6">
                                <div className="search-container">
                                    <input
                                        type="text"
                                        placeholder="Kullanıcıları ara..."
                                        className="search-input"
                                        value={searchTerm}
                                        onChange={(e) => setSearchTerm(e.target.value)}
                                    />
                                    <Button variant="success" onClick={handleExportToExcel}>
                                        <i className="fas fa-file-excel"></i>
                                    </Button>
                                </div>
                            </th>
                        </tr>
                        <tr>
                            <th className="dark-bg-color" scope="col"></th>
                            <th className="dark-bg-color dark-tb-body-text" scope="col">ADI | SOYADI</th>
                            <th className="dark-bg-color dark-tb-body-text" scope="col">OKULU</th>
                            <th className="dark-bg-color dark-tb-body-text" scope="col">ŞEHİR</th>
                            <th className="dark-bg-color dark-tb-body-text">İŞLEMLER</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredUsers.map((user, index) => (
                            <tr key={user.id}>
                                <th className="dark-bg-color dark-tb-body-text" scope="row">{index + 1}</th>
                                <td className="dark-tb-body-text">{user.firstName + " " + user.lastName}</td>
                                <td className="dark-tb-body-text">{user.institution.name}</td>
                                <td className="dark-tb-body-text">{user.city.name}</td>
                                <td>
                                    {/* <button
                                        className="btn btn-sm btn-warning dark-tb-update-btn"
                                        onClick={() => handleUpdate(User.id)}
                                    >
                                        <i className="fas fa-edit"></i>
                                    </button> */}

                                    <button
                                        className="btn btn-sm btn-info dark-tb-update-btn"
                                        onClick={() => handleInfo(user.id)}
                                    >
                                        <i className="fas fa-info-circle"></i>
                                    </button>

                                    <button
                                        className="btn btn-sm btn-danger dark-tb-delete-btn"
                                        onClick={() => handleDelete(user.id)}
                                    >
                                        <i className="fas fa-trash"></i>
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            {/* Güncelleme Modal */}
            <Modal show={showUpdateModal} onHide={handleCloseUpdateModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Güncelleme İşlemi</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3">
                            <Form.Label>Adı Soyadı</Form.Label>
                            <Form.Control
                                type="text"
                                name="name"
                                value={updateFormData.name}
                                onChange={updateHandleInputChange}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Görev Yeri</Form.Label>
                            <Form.Control
                                type="text"
                                name="dutyLocation"
                                value={updateFormData.dutyLocation}
                                onChange={updateHandleInputChange}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Görev Tanımı</Form.Label>
                            <Form.Control
                                type="text"
                                name="dutyDescription"
                                value={updateFormData.dutyDescription}
                                onChange={updateHandleInputChange}
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseUpdateModal}>
                        Kapat
                    </Button>
                    <Button variant="primary" onClick={updateHandleSave}>
                        Güncellemeyi Kaydet
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Silme Modal */}
            <Modal show={showDeleteModal} onHide={handleCloseDeleteModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Silme İşlemi</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>ID: {selectedUserId} silmek istediğinize emin misiniz?</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseDeleteModal}>
                        İptal
                    </Button>
                    <Button variant="danger" onClick={confirmDelete}>
                        Sil
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Add Data Modal */}
            <Modal show={showAddModal} onHide={handleCloseAddModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Yeni Üye Ekle</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3">
                            <Form.Label>Adı Soyadı</Form.Label>
                            <Form.Control
                                type="text"
                                name="name"
                                value={addFormData.name}
                                onChange={addHandleInputChange}
                                placeholder="Adı Soyadı Girin"
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Görev Yeri</Form.Label>
                            <Form.Control
                                type="text"
                                name="dutyLocation"
                                value={addFormData.dutyLocation}
                                onChange={addHandleInputChange}
                                placeholder="Görev Yeri Girin"
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Görev Tanımı</Form.Label>
                            <Form.Control
                                type="text"
                                name="dutyDescription"
                                value={addFormData.dutyDescription}
                                onChange={addHandleInputChange}
                                placeholder="Görev Tanımı Girin"
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseAddModal}>
                        Kapat
                    </Button>
                    <Button variant="primary" onClick={addHandleSave}>
                        Kaydet
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Bilgi Modal */}
            <Modal show={showInfoModal} onHide={handleCloseInfoModal} dialogClassName="exam-result-custom-modal-width">
                <Modal.Header closeButton>
                    <Modal.Title>Kullanıcı Bilgileri</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3">
                            <Form.Label>Adı Soyadı</Form.Label>
                            <Form.Control
                                type="text"
                                name="firstName"
                                value={infoFormData.firstName + ' ' + infoFormData.lastName}
                                readOnly
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Doğum Tarihi</Form.Label>
                            <Form.Control
                                type="text"
                                name="birthdate"
                                value={infoFormData.birthdate}
                                readOnly
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Email</Form.Label>
                            <Form.Control
                                type="text"
                                name="email"
                                value={infoFormData.email}
                                readOnly
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Şehir</Form.Label>
                            <Form.Control
                                type="text"
                                name="city"
                                value={infoFormData.city}
                                readOnly
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>İlçe</Form.Label>
                            <Form.Control
                                type="text"
                                name="district"
                                value={infoFormData.district}
                                readOnly
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Okul</Form.Label>
                            <Form.Control
                                type="text"
                                name="institution"
                                value={infoFormData.institution}
                                readOnly
                            />
                        </Form.Group>

                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseInfoModal}>
                        Kapat
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}
