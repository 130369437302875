import React from 'react';
import '../../styles/Modal.css';

const ConfirmationModalOneButton = ({ show, handleClose, handleConfirm, title, body, buttonText }) => {
    // Modalın görünürlüğünü kontrol et
    const modalClass = show ? "my-modal show" : "my-modal";
    //⚠️ UYARI

    return (
        <div className={modalClass}>
            <div className="my-modal-content">
                <button className="my-modal-close-button" onClick={handleClose}>X</button>
                <div className="my-modal-body">
                    <p>{title}</p>
                    <p>{body}</p>
                </div>
                <div className="my-modal-footer">
                    <button className="btn my-modal-refuse-button" onClick={handleConfirm}>{buttonText}</button>
                </div>
            </div>
        </div>
    );
};

export default ConfirmationModalOneButton;
