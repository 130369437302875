import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { Modal, Button, Form } from 'react-bootstrap';
import AccommodationService from '../../services/AccommodationService';
import CityService from '../../services/CityService';
import InstitutionService from '../../services/InstitutionService';
import { toast } from "react-toastify";

export default function ManageAccommodation() {

    const accommodationService = new AccommodationService();
    const cityService = new CityService();
    const institutionService = new InstitutionService();

    const [refreshData, setRefreshData] = useState(false);

    const [showUpdateModal, setShowUpdateModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [selectedAccomodationUserId, setSelectedAccomodationUserId] = useState(null);

    const [showAddModal, setShowAddModal] = useState(false);
    const [addFormData, setAddFormData] = useState({
        city: '',
        institutionName: '',
        advisor: {
            name: '',
            tc: ''
        },
        students: [{
            name: '',
            tc: ''
        },
        {
            name: '',
            tc: ''
        }]
    });

    const [updateFormData, setUpdateFormData] = useState({
        city: '',
        institutionName: '',
        advisor: {
            name: '',
            tc: ''
        },
        students: [{
            name: '',
            tc: ''
        },
        {
            name: '',
            tc: ''
        }]
    });

    const [accommodations, setAccommodations] = useState([]);

    const [cities, setCities] = useState([]);
    const [selectedCity, setSelectedCity] = useState('');

    const [schools, setSchools] = useState([]);
    const [selectedSchool, setSelectedSchool] = useState('');

    useEffect(() => {

        cityService.getAllCity().then(result => {

            //console.log(result.data.data)
            if (result.data.success) {
                setCities(result.data.data);
            }

        }).catch(error => {
            toast.error("Şehirler listelerken bir sorun oluştu!");
        })

        institutionService.getAllInstitutions().then(result => {

            //console.log(result.data.data)
            if (result.data.success) {
                setSchools(result.data.data);
            }

        }).catch(error => {
            toast.error("Okul isimleri listelerken bir sorun oluştu!");
        })

    }, []);

    const filteredSchools = selectedCity
        ? schools.filter(school => school.city === selectedCity)
        : schools;

    const handleUpdate = (id) => {
        // Güncelleme modalını açma

        const selectedAccomodation = accommodations.find(accommodation => accommodation.userId === id);

        const updatedStudents = [...selectedAccomodation.students];
        if (updatedStudents.length < 2) {
            updatedStudents.push({ name: '', tc: '' });
        }

        setSelectedAccomodationUserId(id);
        setSelectedCity(selectedAccomodation.city)
        setUpdateFormData({
            userId: selectedAccomodation.userId,
            city: selectedAccomodation.city,
            institutionName: selectedAccomodation.institutionName,
            advisor: selectedAccomodation.advisor,
            students: updatedStudents
        });

        setShowUpdateModal(true);
    };


    const handleCloseUpdateModal = () => {
        // Güncelleme modalını kapama

        setShowUpdateModal(false);
        setSelectedAccomodationUserId(null);
        setSelectedCity('')
        setUpdateFormData({
            city: '',
            institutionName: '',
            advisor: {
                name: '',
                tc: ''
            },
            students: [
                { name: '', tc: '' },
                { name: '', tc: '' }
            ]
        });
    };

    const updateHandleInputChange = (e) => {
        // Form verilerini güncelleme

        const { name, value, dataset } = e.target;

        if (name === "city") {

            setSelectedCity(value);
        }
        else if (name.startsWith('student-')) {

            const index = parseInt(name.split('-')[1], 10);
            const field = dataset.field;

            setUpdateFormData(prevState => ({
                ...prevState,
                students: prevState.students.map((student, i) =>
                    i === index ? { ...student, [field]: value } : student
                )
            }));
        }
        else {

            setUpdateFormData(prevState => ({
                ...prevState,
                [name]: value
            }));
        }
    };

    const handleDelete = (id) => {
        // Silme modalını açma

        setSelectedAccomodationUserId(id);
        setShowDeleteModal(true);
    };


    const handleCloseDeleteModal = () => {
        // Silme modalını kapama

        setShowDeleteModal(false);
        setSelectedAccomodationUserId(null);
    };


    const confirmDelete = () => {
        // Silme işlemi

        accommodationService.deleteAccomodationByUserId(selectedAccomodationUserId).then(result => {

            //console.log(result)
            if (result.status === 200) {
                toast.success("Kayıt silindi.");
                setRefreshData(prev => !prev);
            }

        }).catch(error => {
            toast.error("Kayıt silinirken bir hata oluştu!");
        })

        setShowDeleteModal(false);
    };

    const updateHandleSave = () => {
        // Formu kaydetme

        //console.log('Güncellenen veriler:', updateFormData);

        accommodationService.saveAccommodation(updateFormData).then(result => {

            //console.log(result.data.data)
            if (result.data.success) {
                toast.success("Veriler güncellendi.")
                setRefreshData(prev => !prev);

            } else {
                toast.error(result.data.data.message)
            }

        }).catch(error => {
            toast.error("Konaklama bilgileri güncellenirken bir sorun oluştu!");
        })

        setShowUpdateModal(false);
    };

    const handleAdd = () => {
        // Add Data modalını açma
        setShowAddModal(true);
    };

    const handleCloseAddModal = () => {
        // Add Data modalını kapama
        setShowAddModal(false);
        setSelectedCity(null)
        setAddFormData({
            city: '',
            institutionName: '',
            advisor: {
                name: '',
                tc: ''
            },
            students: [{
                name: '',
                tc: ''
            },
            {
                name: '',
                tc: ''
            }]
        });
    };

    const addHandleInputChange = (e) => {
        // Form verilerini güncelleme
        const { name, value } = e.target;

        if (name === "city") {
            setSelectedCity(value)
        }

        if (name.startsWith('student-')) {

            const index = parseInt(name.split('-')[1]);  // "student-0" ya da "student-1" den index alıyoruz
            setAddFormData((prevState) => ({
                ...prevState,
                students: prevState.students.map((student, i) =>
                    i === index ? { ...student, [e.target.dataset.field]: value } : student
                ),
            }));

        } else {
            setAddFormData(prevState => ({
                ...prevState,
                [name]: value
            }));
        }

    };

    const addHandleSave = () => {

        accommodationService.saveAccommodation(addFormData).then(result => {

            //console.log(result.data.data)
            if (result.data.success) {

                toast.success("Veriler kaydedildi.")
                setRefreshData(prev => !prev);
                setAddFormData({
                    city: '',
                    institutionName: '',
                    advisor: {
                        name: '',
                        tc: ''
                    },
                    students: [{
                        name: '',
                        tc: ''
                    },
                    {
                        name: '',
                        tc: ''
                    }]
                });

            } else {
                toast.error(result.data.data.message)
            }

        }).catch(error => {
            toast.error("Konaklama bilgileri kayıt edilirken bir sorun oluştu!");
        })

        setShowAddModal(false);
    };

    useEffect(() => {

        accommodationService.getAllAccommodationRecords().then(result => {

            //console.log(result)
            if (result.data.success) {

                setAccommodations(result.data.data);
            }

        }).catch(error => {
            toast.error("Konaklama kayıtları listelenirken bir hata oluştu!");
        })

    }, [refreshData]);

    return (
        <div className="dark-tb-main">
            <div className="dark-tb-container text-light">
                <table className="table table-dark table-bordered mt-3">
                    <thead>
                        <tr>
                            <th className="dark-bg-color tb-header-text" scope="col" colSpan="6">Konaklama Kayıtları</th>
                        </tr>
                        <tr>
                            <th className="dark-bg-color" scope="col"></th>
                            <th className="dark-bg-color dark-tb-body-text" scope="col">ADI | SOYADI</th>
                            <th className="dark-bg-color dark-tb-body-text" scope="col">ŞEHİR</th>
                            <th className="dark-bg-color dark-tb-body-text" scope="col">KURUM</th>
                            <th className="dark-bg-color dark-tb-body-text" scope="col">İŞLEMLER
                                <div>
                                    {/* <Button variant="success" onClick={handleAdd}>
                                    <i className="fas fa-plus"></i>
                                </Button> */}
                                </div>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {accommodations.map((accommodation, index) => (
                            <tr key={accommodation.userId}>
                                <th className="dark-bg-color dark-tb-body-text" scope="row">{index + 1}</th>
                                <td className="dark-tb-body-text">{accommodation.advisor.name}</td>
                                <td className="dark-tb-body-text">{accommodation.city}</td>
                                <td className="dark-tb-body-text">{accommodation.institutionName}</td>
                                <td>
                                    <button
                                        className="btn btn-sm btn-warning dark-tb-update-btn"
                                        onClick={() => handleUpdate(accommodation.userId)}
                                    >
                                        <i className="fas fa-edit"></i>
                                    </button>
                                    <button
                                        className="btn btn-sm btn-danger dark-tb-delete-btn"
                                        onClick={() => handleDelete(accommodation.userId)}
                                    >
                                        <i className="fas fa-trash"></i>
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            {/* Güncelleme Modal */}
            <Modal show={showUpdateModal} onHide={handleCloseUpdateModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Güncelleme İşlemi</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3">
                            <Form.Label>Şehir</Form.Label>
                            <Form.Select
                                name="city"
                                value={updateFormData.city}
                                onChange={updateHandleInputChange}
                            >
                                <option value="">Şehir seçin</option>
                                {cities.map((city) => (
                                    <option key={city.id} value={city.name}>
                                        {city.name}
                                    </option>
                                ))}
                            </Form.Select>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Kurum</Form.Label>
                            <Form.Select
                                name="institutionName"
                                value={updateFormData.institutionName}
                                onChange={updateHandleInputChange}
                            >
                                <option value="">Kurum seçin</option>
                                {filteredSchools.map((institutionName) => (
                                    <option key={institutionName.id} value={institutionName.name}>
                                        {institutionName.name}
                                    </option>
                                ))}
                            </Form.Select>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Danışman Adı - Soyadı</Form.Label>
                            <Form.Control
                                type="text"
                                name="advisor"
                                value={updateFormData.advisor.name}
                                onChange={updateHandleInputChange}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Danışman TC</Form.Label>
                            <Form.Control
                                type="text"
                                name="advisor"
                                value={updateFormData.advisor.tc}
                                onChange={updateHandleInputChange}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>1. Öğrenci Adı - Soyadı</Form.Label>
                            <Form.Control
                                type="text"
                                name="student-0"
                                data-field="name"
                                value={updateFormData.students[0].name}
                                onChange={updateHandleInputChange}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>1. Öğrenci TC</Form.Label>
                            <Form.Control
                                type="text"
                                name="student-0"
                                data-field="tc"
                                value={updateFormData.students[0].tc}
                                onChange={updateHandleInputChange}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>2. Öğrenci Adı - Soyadı</Form.Label>
                            <Form.Control
                                type="text"
                                name="student-1"
                                data-field="name"
                                value={updateFormData.students[1]?.name || ""}
                                onChange={updateHandleInputChange}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>2. Öğrenci TC</Form.Label>
                            <Form.Control
                                type="text"
                                name="student-1"
                                data-field="tc"
                                value={updateFormData.students[1]?.tc || ""}
                                onChange={updateHandleInputChange}
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseUpdateModal}>
                        Kapat
                    </Button>
                    <Button variant="primary" onClick={updateHandleSave}>
                        Güncellemeyi Kaydet
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Silme Modal */}
            <Modal show={showDeleteModal} onHide={handleCloseDeleteModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Silme İşlemi</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Silmek istediğinize emin misiniz?</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseDeleteModal}>
                        İptal
                    </Button>
                    <Button variant="danger" onClick={confirmDelete}>
                        Sil
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Add Data Modal */}
            {/* <Modal show={showAddModal} onHide={handleCloseAddModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Yeni Üye Ekle</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3">
                            <Form.Label>Şehir</Form.Label>
                            <Form.Select
                                name="city"
                                value={addFormData.city}
                                onChange={addHandleInputChange}
                            >
                                <option value="">Şehir seçin</option>
                                {cities.map((city) => (
                                    <option key={city.id} value={city.name}>
                                        {city.name}
                                    </option>
                                ))}
                            </Form.Select>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Kurum</Form.Label>
                            <Form.Select
                                name="institutionName"
                                value={addFormData.institutionName}
                                onChange={addHandleInputChange}
                            >
                                <option value="">Kurum seçin</option>
                                {filteredSchools.map((institutionName) => (
                                    <option key={institutionName.id} value={institutionName.name}>
                                        {institutionName.name}
                                    </option>
                                ))}
                            </Form.Select>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Danışman Adı - Soyadı</Form.Label>
                            <Form.Control
                                type="text"
                                name="advisor"
                                value={addFormData.advisor.name}
                                onChange={addHandleInputChange}
                                placeholder="Danışman Adı - Soyadı Girin"
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Danışman TC</Form.Label>
                            <Form.Control
                                type="text"
                                name="advisor"
                                value={addFormData.advisor.tc}
                                onChange={addHandleInputChange}
                                placeholder="Danışman TC Girin"
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>1.Öğrenci Adı - Soyadı</Form.Label>
                            <Form.Control
                                type="text"
                                name="student-0"
                                data-field="name"
                                value={addFormData.students[0].name}
                                onChange={addHandleInputChange}
                                placeholder="1.Öğrenci Adı - Soyadı Girin"
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>1.Öğrenci TC</Form.Label>
                            <Form.Control
                                type="text"
                                name="student-0"
                                data-field="tc"
                                value={addFormData.students[0].tc}
                                onChange={addHandleInputChange}
                                placeholder="1.Öğrenci TC Girin"
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>2.Öğrenci Adı - Soyadı</Form.Label>
                            <Form.Control
                                type="text"
                                name="student-1"
                                data-field="name"
                                value={addFormData.students[1].name}
                                onChange={addHandleInputChange}
                                placeholder="2.Öğrenci Adı - Soyadı Girin"
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>2.Öğrenci TC</Form.Label>
                            <Form.Control
                                type="text"
                                name="student-1"
                                data-field="tc"
                                value={addFormData.students[1].tc}
                                onChange={addHandleInputChange}
                                placeholder="2.Öğrenci TC Girin"
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseAddModal}>
                        Kapat
                    </Button>
                    <Button variant="primary" onClick={addHandleSave}>
                        Kaydet
                    </Button>
                </Modal.Footer>
            </Modal> */}
        </div>
    );
}
