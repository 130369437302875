import React, { useState } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { useNavigate } from "react-router-dom";
import UserService from '../services/UsersService';
import { toast } from "react-toastify";

export default function ForgotPassword() {

    const navigate = useNavigate();
    const userService = new UserService()

    const [email, setEmail] = useState('');
    const [verificationCode, setVerificationCode] = useState('');
    const [verifyVerificationCode, setVerifyVerificationCode] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const [step, setStep] = useState(1);

    const [errors, setErrors] = useState({});

    const validate = () => {
        const newErrors = {};

        if (step === 1) {

            if (!email) {
                newErrors.email = 'Eposta gereklidir';
            } else if (!/\S+@\S+\.\S+/.test(email)) {
                newErrors.email = 'Geçersiz eposta formatı';
            }
        }
        else if (step === 2) {

            if (!verificationCode) {
                newErrors.verificationCode = 'Doğrulama kodu gereklidir';
            }

        }
        else if (step === 3) {

            if (!password) {
                newErrors.password = 'Şifre gereklidir';
            } else if (password.length < 8) {
                newErrors.password = 'Şifre en az 8 karakter olmalıdır';
            }
            if (password !== confirmPassword) {
                newErrors.confirmPassword = 'Şifreler birbiriyle uyuşmuyor';
            }
        }

        return newErrors;
    };

    const sendVerificationCode = () => {


        const validationErrors = validate();

        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
        }
        else {  //hata yoksa           

            setErrors({})

            userService.sendVerificationCode(email).then(result => {

                //console.log(result.data.data)

                setVerifyVerificationCode(result.data.data)

                toast.success("Doğrulama kodu gönderildi!");
                setStep(2);

            }).catch(error => {
                toast.error("Doğrulama kodu gönderilirken bir sorun oluştu, lütfen email adresinizin sisteme kayıtlı olmasına dikkat ediniz!");
                //console.error(error);
            })

        }
    };

    const validateVerificationCode = () => {

        const validationErrors = validate();

        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
        }
        else {
            setErrors({})

            //console.log(verifyVerificationCode)

            if (verificationCode.toString() === verifyVerificationCode.toString()) {
                setStep(3);
                toast.success("Doğrulama kodu doğru!");
            }
            else {
                toast.error("Girilen doğrulama kodu eşleşmiyor, lütfen epostanızı kontrol ediniz veya tekrar doğrulama kodu gönderiniz!");
            }
        }

    };

    const resetPassword = () => {

        const validationErrors = validate();

        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
        }
        else {
            setErrors({})

            const updateDatas = {
                "email": email,
                "password": password
            }

            userService.updateUserPassword(updateDatas).then(result => {

                if (result.data.success) {
                    setPassword('');
                    setConfirmPassword('');
                    toast.success("Şifreniz başarıyla yenilendi!");

                    navigate("/home/signIn")
                } else {
                    toast.error("Şifre güncelleme sırasında bir sorun oluştu, lütfen tekrar deneyiniz!");
                }


            }).catch(error => {
                toast.error("Şifre güncelleme sırasında bir sorun oluştu, lütfen tekrar deneyiniz!");
                console.error(error);
            })


        }
    };

    return (

        <div className="main">

            <div className='container'>

                <div className="forgot-password-section">

                    <div className="card p-4">

                        {step === 1 && (
                            <div>

                                <h2 className="text-center mb-5">Şifre Yenile</h2>
                                <form>
                                    <div className="mb-3">
                                        <label htmlFor="email" className="form-label">Eposta</label>
                                        <input
                                            type="email"
                                            id="email"
                                            className="form-control form-input"
                                            required
                                            onChange={(e) => setEmail(e.target.value)}
                                            value={email}
                                        />
                                        {errors.email && <small className="text-danger">{errors.email}</small>}
                                    </div>

                                    <button type="button" className="forgot-password-btn w-100 mb-3" onClick={sendVerificationCode}>
                                        Doğrulama Kodu Gönder
                                    </button>
                                    {/* <a
                                        href="#"
                                        className="d-block text-center mb-3"
                                        onClick={(event) => {
                                            event.preventDefault(); // Sayfanın yenilenmesini engeller
                                            sendVerificationCode();  
                                        }}
                                    >
                                        Tekrar Gönder
                                    </a> */}
                                </form>

                            </div>)}


                        {step === 2 && (
                            <div>

                                <h2 className="text-center mb-5">Şifre Yenile</h2>
                                <form>
                                    <div className="mb-3">
                                        <label htmlFor="email" className="form-label">Doğrulama Kodu</label>
                                        <input
                                            type="text"
                                            id="verification-code"
                                            className="form-control form-input"
                                            required
                                            onChange={(e) => setVerificationCode(e.target.value)}
                                            value={verificationCode}
                                        />
                                        {errors.email && <small className="text-danger">{errors.email}</small>}
                                    </div>

                                    <button type="button" className="forgot-password-btn w-100 mb-3" onClick={validateVerificationCode}>
                                        Gönder
                                    </button>
                                </form>

                            </div>)}

                        {step === 3 && (
                            <div>

                                <h2 className="text-center mb-5">Yeni Şifre Oluştur</h2>
                                <form>
                                    <div className="mb-3">
                                        <label htmlFor="password" className="form-label">Yeni Şifre</label>
                                        <input
                                            type={showPassword ? "text" : "password"}
                                            id="password"
                                            className="form-control form-input"
                                            required
                                            onChange={(e) => setPassword(e.target.value)}
                                            value={password}
                                        />
                                        <i
                                            className={`fas ${showPassword ? "fa-eye-slash" : "fa-eye"} hide-password-icon`}
                                            onClick={() => setShowPassword(!showPassword)}
                                        ></i>
                                        {errors.password && <small className="text-danger">{errors.password}</small>}
                                    </div>

                                    <div className="mb-3">
                                        <label htmlFor="confirmPassword" className="form-label">Yeni Şifreyi Onayla</label>
                                        <input
                                            type={showConfirmPassword ? "text" : "password"}
                                            id="confirmPassword"
                                            className="form-control form-input"
                                            required
                                            onChange={(e) => setConfirmPassword(e.target.value)}
                                            value={confirmPassword}
                                        />
                                        <i
                                            className={`fas ${showConfirmPassword ? "fa-eye-slash" : "fa-eye"} hide-confirm-password-icon`}
                                            onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                                        ></i>
                                        {errors.confirmPassword && <small className="text-danger">{errors.confirmPassword}</small>}
                                    </div>

                                    <p className="text-center small mt-4">
                                        Güvenliğiniz için güçlü bir şifre oluşturun. Yaygın kelimelerden ve sıralı rakamlardan kaçının.
                                    </p>

                                    <button type="button" className="forgot-password-btn w-100 mb-3" onClick={resetPassword}>
                                        Şifreyi Yenile
                                    </button>
                                </form>

                            </div>)}

                    </div>

                </div>

            </div>


            <style jsx>
                {`

                .main {
                    display: flex;
                    margin: 2rem 0;
                    text-align: center;
                    background-image: url('/images/robo-rally-text.png');
                    background-size: cover; /* Resmi kapsayacak şekilde ayarlar */
                    background-position: center; /* Resmi ortalar */
                    background-repeat: no-repeat; /* Resmin tekrarını engeller */     
                    width: 100vw;
                }
    
                .container{
                    height: 50rem;
                }

                .forgot-password-section{
                    align-items: center;
                    display: flex;
                    justify-content: center;
                }

                .card {
                    border-radius: 70px;
                    background-color: rgba(255, 255, 255, 0.4);
                    margin-top: 1rem;
                    align-items: center; 
                    box-shadow: -1px -2px 3px 3px rgba(220, 214, 214, 5);
                    width: 100%;
                    max-width: 35rem;
                    height: 35rem;
                }

                .h2 {
                    font-size: 1rem;
                    font-weight: bold;
                    margin-top: 2rem;
                    font-family: PoppinsBold;                    
                }

                .form-label {               
                    display: block;
                    text-align: start;
                    margin-top: 1rem;
                    width: 25rem;
                    font-family: PoppinsRegular;
                    font-size: 1rem;
                }

                .form-input {
                    border: none;
                    border-bottom: 2px solid #000;
                    border-radius: 0;
                    background-color: transparent;
                    width: 25rem;
                }

                .hide-password-icon {
                    top: 10rem;
                    right: 6rem; 
                    transform: translateY(-50%); 
                    cursor: pointer;
                    position: absolute;
                }

                .hide-confirm-password-icon {
                    top: 15.5rem;
                    right: 6rem; 
                    transform: translateY(-50%); 
                    cursor: pointer;
                    position: absolute;
                }

                p.small {
                    max-width: 300px;
                    margin: 0 auto;
                    text-align: center;
                }

                .forgot-password-btn{
                    background-color: #8f9396;
                    border: 0;
                    color: #ffffff;
                    border-radius: 15px;
                    padding-top: 0.9rem;
                    padding-bottom: 0.9rem;
                    margin-top: 2rem;
                    font-family: PoppinsMedium;
                    font-size: 1rem;                    
                }                  

                a {
                    color: #000;
                    fontFamily: PoppinsRegular;
                    fontSize: 1rem;
                }

                p {                
                    fontFamily: PoppinsRegular;
                    fontSize: 2rem;
                }

                .underline {
                    text-decoration: underline;
                    margin-right: 0.3rem;
                    margin-left: 0.3rem;
                    cursor: pointer;
                }

                /* phones */
                @media (max-width: 768px) {

                    .main {
                        margin: 1rem 0;
                        text-align: center;
                        background-image: url('./images/robo-rally-text.png');
                        background-size: contain;
                        /* Resmi kapsayacak şekilde ayarlar */
                        background-position: top;
                        /* Resmi ortalar */
                        background-repeat: no-repeat;
                        /* Resmin tekrarını engeller */
                        height: 50rem;
                    }

                    .forgot-password-section {
                        align-items: center;
                        display: flex;
                        justify-content: center;
                    }

                    .card {
                        border-radius: 70px;
                        background-color: rgba(255, 255, 255, 0.4);
                        margin-top: -1.5rem;
                        align-items: center;
                        box-shadow: -1px -2px 3px 3px rgba(220, 214, 214, 5);
                        width: 100%;
                        max-width: 35rem;
                        height: 35rem;
                    }

                    h2 {
                        font-size: 1.5rem;
                        font-weight: bold;
                        margin-top: 0.5rem;
                    }

                    .form-label {
                        display: block;
                        text-align: start;
                        margin-top: 1rem;
                        width: 10rem;
                        font-family: PoppinsRegular;
                        font-size: 1rem;
                    }

                    .form-input {
                        border: none;
                        border-bottom: 2px solid #000;
                        border-radius: 0;
                        background-color: transparent;
                        width: 100%;
                    }

                    .hide-password-icon {
                        top: 10rem;
                        right: 3rem;
                        transform: translateY(-50%);
                        cursor: pointer;
                        position: absolute;
                    }

                    .hide-confirm-password-icon {
                        top: 15.5rem;
                        right: 3rem; 
                        transform: translateY(-50%); 
                        cursor: pointer;
                        position: absolute;
                    }

                    .forgot-password-btn {
                        background-color: #000;
                        color: #ffffff;
                        border-radius: 15px;
                        padding-top: 0.7rem;
                        padding-bottom: 0.7rem;
                        margin-top: 2rem;
                        font-family: PoppinsMedium;
                        font-size: 1rem;
                    }

                    a {
                        color: #000;
                        fontFamily: PoppinsRegular;
                        fontSize: 1rem;
                    }

                    p {                
                        fontFamily: PoppinsRegular;
                        fontSize: 1rem;
                    }

                    .underline {
                        text-decoration: underline;
                        margin-right: 0.3rem;
                        margin-left: 0.3rem;
                        cursor: pointer;
                        font-size: 0.7rem;
                    }

                }
             

             `}
            </style>

        </div>


    )
}
