import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import '../styles/Accommodation.css';
import { useNavigate } from "react-router-dom";
import AccommodationService from '../services/AccommodationService';
import CityService from '../services/CityService';
import InstitutionService from '../services/InstitutionService';
import { toast } from "react-toastify";

export default function Accommodation() {
    const navigate = useNavigate();
    const accommodationService = new AccommodationService();
    const cityService = new CityService();
    const institutionService = new InstitutionService();

    const userId = sessionStorage.getItem("userId");

    const [cities, setCities] = useState([]);
    const [selectedCity, setSelectedCity] = useState('');

    const [schools, setSchools] = useState([]);
    const [selectedSchool, setSelectedSchool] = useState('');

    const [advisor, setAdvisor] = useState({ name: '', tc: '' });
    const [currentAdvisor, setCurrentAdvisor] = useState({ name: '', tc: '' });
    const [advisorError, setAdvisorError] = useState({});

    const [students, setStudents] = useState([]);
    const [currentStudent, setCurrentStudent] = useState({ name: '', tc: '' });
    const [studentError, setStudentError] = useState({});

    useEffect(() => {

        cityService.getAllCity().then(result => {

            //console.log(result.data.data)
            if (result.data.success) {
                setCities(result.data.data);
            }

        }).catch(error => {
            toast.error("Şehirler listelerken bir sorun oluştu!");
        })

        institutionService.getAllInstitutions().then(result => {

            //console.log(result.data.data)
            if (result.data.success) {
                setSchools(result.data.data);
            }

        }).catch(error => {
           // toast.error("Okul isimleri listelerken bir sorun oluştu!");
        })

        accommodationService.getAccomodationByUserId(userId).then(result => {

            //console.log(result.data.data)
            if (result.data.success) {

                const savedAccommodationDatas = result.data.data;
                if(savedAccommodationDatas !== null){
                    setSelectedCity(savedAccommodationDatas.city)
                    setSelectedSchool(savedAccommodationDatas.institutionName)
                    setAdvisor(savedAccommodationDatas.advisor)
                    setStudents(savedAccommodationDatas.students)
                }
               
            }

        }).catch(error => {
            //toast.error("Okul isimleri listelerken bir sorun oluştu!");
        })

    }, []);

   

    const filteredSchools = selectedCity
        ? schools.filter(school => school.city === selectedCity)
        : schools;

    const isValidTc = (tc) => {
        // TC numarası 11 haneli olmalı ve ilk karakter 0 olamaz
        if (!/^[1-9][0-9]{10}$/.test(tc)) return false;

        let sumOdd = 0;  // 1, 3, 5, 7, 9. basamakların toplamı
        let sumEven = 0; // 2, 4, 6, 8. basamakların toplamı
        let sumFirst10 = 0;  // İlk 10 basamağın toplamı

        // Basamakları dolaşarak toplamları hesapla
        for (let i = 0; i < 9; i++) {
            const digit = parseInt(tc[i]);
            sumFirst10 += digit;
            if (i % 2 === 0) {
                sumOdd += digit;  // Tek basamaklar (1, 3, 5...)
            } else {
                sumEven += digit; // Çift basamaklar (2, 4, 6...)
            }
        }

        const digit10 = ((sumOdd * 7) - sumEven) % 10;  // 10. hane
        sumFirst10 += parseInt(tc[9]);  // 10. basamağı da ilk 10 toplamına ekle
        const digit11 = sumFirst10 % 10;  // 11. hane

        // 10. ve 11. basamaklar kontrol ediliyor
        return digit10 === parseInt(tc[9]) && digit11 === parseInt(tc[10]);
    };

    const handleAddAdvisor = () => {
        if (!currentAdvisor.name || !currentAdvisor.tc) {
            setAdvisorError({ advisor: 'Danışman adı ve TC numarası gerekli.' });
            setAdvisor({ name: '', tc: '' })
            return;
        }
        if (!isValidTc(currentAdvisor.tc)) {
            setAdvisorError({ advisor: 'Geçerli bir TC numarası giriniz.' });
            setAdvisor({ name: '', tc: '' })
            return;
        }
        setAdvisorError({});


        setAdvisor({ name: currentAdvisor.name, tc: currentAdvisor.tc });

    };

    const handleAddStudent = () => {
        if (students.length >= 2) {
            setStudentError({ student: 'En fazla iki öğrenci ekleyebilirsiniz.' });
            return;
        }
        if (!currentStudent.name || !currentStudent.tc) {
            setStudentError({ student: 'Öğrenci adı ve TC numarası gerekli.' });
            return;
        }
        if (!isValidTc(currentStudent.tc)) {
            setStudentError({ student: 'Geçerli bir TC numarası giriniz.' });
            return;
        }
        setStudents([...students, currentStudent]);
        setCurrentStudent({ name: '', tc: '' });
        setStudentError({});
    };

    const handleDeleteStudent = (indexToDelete) => {
        setStudents(students.filter((_, index) => index !== indexToDelete));
    };

    const handleSubmit = () => {

        if (!advisor.name || !students.length || !selectedCity || !selectedSchool) {
            toast.error('Lütfen tüm alanları doldurun ve en az bir öğrenci ekleyin.');
            return;
        }

        const datas = { userId: userId, city: selectedCity, institutionName: selectedSchool, advisor, students };
        //console.log(datas)

        accommodationService.saveAccommodation(datas).then(result => {

            //console.log(result.data.data)
            if (result.data.success) {
                toast.success('Konaklama bilgileri başarıyla kayıt edildi!')

                setSelectedCity("")
                setSelectedSchool("")
                setAdvisor({ name: "", tc: "" })
                setCurrentAdvisor({ name: "", tc: "" })
                setStudents([])
                setCurrentStudent({ name: "", tc: "" })
            }else{
                toast.error(result.data.data.message)
            }

        }).catch(error => {
            toast.error("Konaklama bilgileri kayıt edilirken bir sorun oluştu!");
        })

    };

    return (
        <div className="accommodation-bg-img">
            <div className='accommodation-container'>
                <div className="accommodation-card p-4">

                    <div>
                        {/* İl ve Kurum Adı Seçimi */}
                        <form className='accommodation-form'>
                            <div className="mb-3">
                                <label htmlFor="city" className="accommodation-form-label">İL</label>
                                <select
                                    id="city"
                                    className="accommodation-custom-input form-control"
                                    onChange={(e) => setSelectedCity(e.target.value)}
                                    value={selectedCity}
                                >
                                    <option value="">Şehir seçiniz</option>
                                    {cities
                                    .filter((city) => city.name !== "İSTANBUL")
                                    .map((city) => (
                                        <option key={city.id} value={city.name}>
                                            {city.name}
                                        </option>
                                    ))}
                                </select>
                            </div>

                            <div className="mb-3">
                                <label htmlFor="schoolName" className="accommodation-form-label">KURUM ADI</label>
                                <select
                                    id="schoolName"
                                    className="accommodation-custom-input form-control"
                                    onChange={(e) => setSelectedSchool(e.target.value)}
                                    value={selectedSchool}
                                >
                                    <option value="">Kurum adı seçiniz</option>
                                    {filteredSchools.map((school) => (
                                        <option key={school.id} value={school.name}>
                                            {school.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </form>

                        <form className='accommodation-form'>
                            {/* Danışman Formu */}
                            <div className="mb-3">
                                <label htmlFor="advisorName" className="accommodation-form-label">Danışman Ad-Soyad</label>
                                <input
                                    type="text"
                                    id="advisorName"
                                    className="form-control accommodation-form-input"
                                    required
                                    onChange={(e) => setCurrentAdvisor({ ...currentAdvisor, name: e.target.value })}
                                    value={currentAdvisor.name}
                                />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="advisorTc" className="accommodation-form-label">Danışma TC</label>
                                <input
                                    type="text"
                                    id="advisorTc"
                                    className="form-control accommodation-form-input"
                                    required
                                    onChange={(e) => setCurrentAdvisor({ ...currentAdvisor, tc: e.target.value })}
                                    value={currentAdvisor.tc}
                                />
                            </div>
                            {advisorError.advisor && <small className="text-danger">{advisorError.advisor}</small>}

                            <div className='add-student-btn-container'>
                                <button type="button" className="add-student-btn w-50 mb-3" onClick={handleAddAdvisor}>
                                    Danışman Ekle
                                </button>
                            </div>

                            {advisor.name && advisor.tc && (
                                <div className='accommodation-advisor-card'>
                                    <p className='accommodation-advisor-card-p accommodation-advisor-card-name-p poppins-mediun-text'> {advisor.name}</p>
                                    <p className='accommodation-advisor-card-p poppins-light-text'>{advisor.tc}</p>
                                </div>
                            )}
                        </form>
                    </div>

                    <div>
                        {/* Öğrenci Formu */}
                        <form className='accommodation-form'>
                            <div className="mb-3">
                                <label htmlFor="studentName" className="accommodation-form-label">Öğrenci Ad-Soyad</label>
                                <input
                                    type="text"
                                    id="studentName"
                                    className="form-control accommodation-form-input"
                                    required
                                    onChange={(e) => setCurrentStudent({ ...currentStudent, name: e.target.value })}
                                    value={currentStudent.name}
                                />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="studentTc" className="accommodation-form-label">Öğrenci TC</label>
                                <input
                                    type="text"
                                    id="studentTc"
                                    className="form-control accommodation-form-input"
                                    required
                                    onChange={(e) => setCurrentStudent({ ...currentStudent, tc: e.target.value })}
                                    value={currentStudent.tc}
                                />
                            </div>
                            {studentError.student && <small className="text-danger">{studentError.student}</small>}

                            <div className='add-student-btn-container'>
                                <button type="button" className="add-student-btn w-50 mb-3" onClick={handleAddStudent}>
                                    Öğrenci Ekle
                                </button>
                            </div>

                            {students.length > 0 && (
                                <div>
                                    {students.map((student, index) => (
                                        <div key={index} className='accommodation-student-card'>
                                            <div>
                                                <p className='accommodation-advisor-card-p accommodation-advisor-card-name-p poppins-mediun-text'>{student.name}</p>
                                                <p className='accommodation-advisor-card-p poppins-light-text'>{student.tc}</p>
                                            </div>

                                            <div className='accommodation-student-card-delete'>
                                                <img
                                                    src={`${process.env.PUBLIC_URL}/images/accomodation/delete-icon.png`}
                                                    alt="Sil"
                                                    className='accommodation-student-card-delete-img'
                                                    onClick={() => handleDeleteStudent(index)}
                                                />
                                            </div>
                                        </div>

                                    ))}
                                </div>
                            )}

                        </form>

                        <p className='accomodation-warning-text-header poppins-mediun-text'>
                            Not:
                        </p>
                        <p className='accomodation-warning-text poppins-mediun-text'>
                            Konaklama kayıtları için maksimum iki öğrenci kaydı yapılabilmektedir.
                            Konaklama kaydı yaparken girdiğiniz bilgilerin doğruluğundan emin olunuz!
                        </p>

                        <button type="button" className="send-accommodation-btn w-100 mb-3" onClick={handleSubmit}>
                            Gönder
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}
