import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/ManageAskUs.css';
import '../styles/YourQuestion.css';
import '../styles/Notification.css';
import '../styles/Loading.css'
import { toast } from "react-toastify";
import NotificationService from '../services/NotificationService';

export default function YourNotifications() {

    const notificationService = new NotificationService()
    const navigate = useNavigate();

    const userId = sessionStorage.getItem("userId");
    const [responseContainers, setResponseContainers] = useState({});

    const [loading, setLoading] = useState(false); // Yüklenme durumu
    const [downloadLoading, setDownloadLoading] = useState(false);

    const [notifications, setNotifications] = useState([]);

    useEffect(() => {

        //console.log(userId)

        if (userId === undefined || userId === null) {
            navigate("/home")

        }
        else {
            setLoading(true)

            notificationService.getAllNotificationsByDateDesc().then(result => {

                if (result.data.success) {
                    setNotifications(result.data.data);
                }
            }).catch(error => {

                toast.error(error.response.data.data.message);
            }).finally(() => {

                setLoading(false);
            });
        }

    }, []);

    function getDaysAgo(createDate) {
        const createdDate = new Date(createDate); // Sorunun oluşturulma tarihi
        const currentDate = new Date(); // Şu anki tarih

        // Günleri sadece tarih kısmı üzerinden karşılaştır
        const createdDateOnly = new Date(createdDate.getFullYear(), createdDate.getMonth(), createdDate.getDate());
        const currentDateOnly = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());

        // Gün farkını hesapla
        const timeDiff = currentDateOnly - createdDateOnly;
        const daysDiff = Math.floor(timeDiff / (1000 * 60 * 60 * 24));

        // Gün farkını döndür (0 ise "Bugün", 1 ise "Dün", diğer durumlar için "X gün önce")
        if (daysDiff === 0) {
            return 'Bugün';
        } else if (daysDiff === 1) {
            return 'Dün';
        } else {
            return `${daysDiff} gün önce`;
        }
    }

    const handleDetailClick = (id) => {
        setResponseContainers(prev => ({
            ...prev,
            [id]: !prev[id] // Belirtilen ID için durumu değiştir
        }));

        notificationService.markAsReadToNotification(id).then(result => {

            if (result.status === 200) {
                //toast.success("Bildirim okundu.");
            }

        })
    };

    const handleDownload = (fileName) => {

        setDownloadLoading(true)

        notificationService.downloadFile(fileName).then(result => {

            //console.log(result.data)
            // Blob URL oluşturma
            const url = window.URL.createObjectURL(new Blob([result.data]));
            const link = document.createElement('a');
            link.href = url;

            // İndirilecek dosyanın adını belirtin
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

            toast.success("Dosya başarıyla indirildi.");
       
            setDownloadLoading(false)

        }).catch(error => {
            console.error(error);
            toast.success("Dosya indirirken bir hata meydana geldi. Hata : " + error);

            setDownloadLoading(false)
        })
        
    };

    return (
        <div className='admin-ask-us-container'>

            <div className="manage-file-first" >
                <p className="manage-file-first-text">Bildirimlerim</p>
            </div>

            <div className='admin-questions-container'>

                {(loading === true || downloadLoading === true) &&
                    <div className="loading-container">
                        <div className="loading-icon"></div> {/* Loading ikonu */}
                        {loading === true && <p>Veriler yükleniyor...</p>}
                        {downloadLoading === true && <p>Dosya indiriliyor...</p>}
                    </div>
                }

                {(loading === false && downloadLoading === false) && (

                    notifications.map((notification, index) => (
                        <div className='manage-questions-container' key={index}>
                            <div className='manage-questions-top'>
                                <div className='manage-questions-profile'>

                                    <i class="fa-regular fa-bell notification-icon"></i>
                                    {getDaysAgo(notification.createDate)}

                                </div>
                            </div>

                            {!responseContainers[notification.id] && (

                                <><div className='manage-questions-mid'>

                                    <p>{notification.title}</p>
                                    <p> {notification.content}</p>
                                    <div className='user-question-bottom'>
                                        <button onClick={() => handleDetailClick(notification.id)}>
                                            Bildirimi Gör
                                        </button>
                                    </div>
                                </div></>
                            )}

                            {responseContainers[notification.id] && (
                                <>
                                    <div className='manage-question-detail'>

                                        <p>{notification.title}</p>
                                        <p>{notification.content}</p>

                                        {notification.link &&
                                            <p>
                                                <span className="notification-link-icon">🔗</span>
                                                <a
                                                    href={notification.link}
                                                    className="notification-link-text"
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    Linke gitmek için tıklayınız
                                                </a>
                                            </p>
                                        }
                                        {notification.fileName &&
                                            <span
                                                style={{ cursor: "pointer" }}
                                                onClick={() => handleDownload(notification.fileName)}
                                            >
                                                📁
                                                <a className="notification-link-text" rel="noopener noreferrer" >
                                                    Dosyaları indirmek için tıklayınız
                                                </a>
                                            </span>
                                        }

                                    </div>

                                    <div className='user-question-bottom'>
                                        <button onClick={() => handleDetailClick(notification.id)}>
                                            Bildirimi Kapat
                                        </button>
                                    </div>
                                </>
                            )}
                        </div>
                    ))
                )}

            </div>

        </div>
    )
}
