import axios from "axios"
import HostAndPortInfo from "../enums/EnHostandPortInfo";

const baseURL = `${HostAndPortInfo.PROTOCOL}://${HostAndPortInfo.HOSTNAME}:${HostAndPortInfo.PORT}`;

export default class NotificationService {

    getAllNotificationsByDateDesc() {

        return axios({
            method: 'get',
            maxBodyLength: Infinity,
            url: `${baseURL}/api/notifications`,
            headers: { 'Authorization': 'Bearer ' + sessionStorage.getItem('token') }

        })
    }

    saveNotification(data) {
     
        return axios({
            method: 'post',
            maxBodyLength: Infinity,
            url: `${baseURL}/api/notifications`,
            headers: { 'Authorization': 'Bearer ' + sessionStorage.getItem('token') },
            data: data
        })
    }

    deleteNotification(id) {
     
        return axios({
            method: 'delete',
            maxBodyLength: Infinity,
            url: `${baseURL}/api/notifications/${id}`,
            headers: { 'Authorization': 'Bearer ' + sessionStorage.getItem('token') },
        })
    }


    downloadFile(fileName) {
        return axios({
            method: 'get',
            responseType: 'blob' ,// Blob olarak veri alıyoruz
            maxBodyLength: Infinity,
            url: `${baseURL}/api/notifications/download?filename=${fileName}`,
            headers: { 'Authorization': 'Bearer ' + sessionStorage.getItem('token') },
        })
    }

    getNotificationReadStatus(fileName) {
        return axios({
            method: 'get',
            maxBodyLength: Infinity,
            url: `${baseURL}/api/notifications-read-status`,
            headers: { 'Authorization': 'Bearer ' + sessionStorage.getItem('token') },
        })
    }

    markAsReadToNotification(notificationId) {
        return axios({
            method: 'post',
            maxBodyLength: Infinity,
            url: `${baseURL}/api/notifications-read-status/mark-as-read/${notificationId}`,
            headers: { 'Authorization': 'Bearer ' + sessionStorage.getItem('token') },
        })
    }

}
