import React, { useState, useEffect } from 'react'
import '../../styles/DarkTable.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Modal, Button, Form } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import OrganizationExecutiveService from '../../services/OrganizationExecutiveService';
import { toast } from "react-toastify";

export default function ManageOrganizationExecutiveList() {

    const navigate = useNavigate();
    const organizationService = new OrganizationExecutiveService();

    const [refreshData, setRefreshData] = useState(false);

    const [showUpdateModal, setShowUpdateModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [selectedExecutiveId, setSelectedExecutiveId] = useState(null);

    const [showAddModal, setShowAddModal] = useState(false);
    const [addFormData, setAddFormData] = useState({
        name: '',
        dutyLocation: '',
        dutyDescription: ''
    });

    const [updateFormData, setUpdateFormData] = useState({
        name: '',
        dutyLocation: '',
        dutyDescription: ''
    });

    const [organizationExecutives, setOrganizationExecutives] = useState([]);


    const handleUpdate = (id) => {
        // Güncelleme modalını açma

        const selectedExecutive = organizationExecutives.find(executive => executive.id === id);

        setSelectedExecutiveId(id);
        setUpdateFormData({
            name: selectedExecutive.name,
            dutyLocation: selectedExecutive.dutyLocation,
            dutyDescription: selectedExecutive.dutyDescription
        });

        setShowUpdateModal(true);
    };


    const handleCloseUpdateModal = () => {
        // Güncelleme modalını kapama

        setShowUpdateModal(false);
        setSelectedExecutiveId(null);
        setUpdateFormData({ name: '', dutyLocation: '', dutyDescription: '' });
    };


    const handleDelete = (id) => {
        // Silme modalını açma

        setSelectedExecutiveId(id);
        setShowDeleteModal(true);
    };


    const handleCloseDeleteModal = () => {
        // Silme modalını kapama

        setShowDeleteModal(false);
        setSelectedExecutiveId(null);
    };


    const confirmDelete = () => {
        // Silme işlemi

        organizationService.deleteOrganizationExecutive(selectedExecutiveId).then(result => {

            //console.log(result)
            if (result.status === 200) {
                toast.success("Üye silindi.");
                setRefreshData(prev => !prev);
            }

        }).catch(error => {
            toast.error("Üye silinirken bir hata oluştu!");
        })

        setShowDeleteModal(false);
    };


    const updateHandleInputChange = (e) => {
        // Form verilerini güncelleme

        const { name, value } = e.target;
        setUpdateFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };


    const updateHandleSave = () => {
        // Formu kaydetme

        //console.log('Güncellenen veriler:', updateFormData);

        organizationService.updateOrganizationExecutive(selectedExecutiveId, updateFormData).then(result => {

            if (result.data.success) {
                toast.success("Veriler güncellendi.")
                setRefreshData(prev => !prev);
            }

        }).catch(error => {
            toast.error("Veriler güncellenirken bir hata oluştu!");
        })

        setShowUpdateModal(false);
    };

    const handleAdd = () => {
        // Add Data modalını açma
        setShowAddModal(true);
    };

    const handleCloseAddModal = () => {
        // Add Data modalını kapama
        setShowAddModal(false);
        setAddFormData({ name: '', dutyLocation: '', dutyDescription: '' });
    };


    const addHandleInputChange = (e) => {
        // Form verilerini güncelleme
        const { name, value } = e.target;
        setAddFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };


    const addHandleSave = () => {

        organizationService.saveOrganizationExecutive(addFormData).then(result => {

            if (result.data.success) {
                toast.success("Veriler kaydedildi.")
                setRefreshData(prev => !prev);
                setAddFormData({ name: '', dutyLocation: '', dutyDescription: '' });
            }

        }).catch(error => {
            toast.error("Veriler kaydedilirken bir hata oluştu!");
        })

        setShowAddModal(false);
    };

    useEffect(() => {

        organizationService.getAllOrganizationExecutives().then(result => {

            //console.log(result)
            if (result.data.success) {

                setOrganizationExecutives(result.data.data);
            }

        }).catch(error => {
            toast.error("Organizasyon yürütme kurulunu listelerken bir hata oluştu!");
        })

    }, [refreshData]);

    return (
        <div className="dark-tb-main">
            <div className="dark-tb-container text-light">
                <table className="table table-dark table-bordered mt-3">
                    <thead>
                        <tr>
                            <th className="dark-bg-color tb-header-text" scope="col" colSpan="6">Organizasyon Yürütme Kurulu</th>
                        </tr>
                        <tr>
                            <th className="dark-bg-color" scope="col"></th>
                            <th className="dark-bg-color dark-tb-body-text" scope="col">ADI | SOYADI</th>
                            <th className="dark-bg-color dark-tb-body-text" scope="col">GÖREV YERİ</th>
                            <th className="dark-bg-color dark-tb-body-text" scope="col">GÖREVİ</th>
                            <th className="dark-bg-color dark-tb-body-text" scope="col">İŞLEMLER
                                <div>
                                    <Button className="dark-tb-add-btn" variant="success" onClick={handleAdd}>
                                        <i className="fas fa-plus"></i>
                                    </Button>
                                </div>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {organizationExecutives.map((executive, index) => (
                            <tr key={executive.id}>
                                <th className="dark-bg-color dark-tb-body-text" scope="row">{index + 1}</th>
                                <td className="dark-tb-body-text">{executive.name}</td>
                                <td className="dark-tb-body-text">{executive.dutyLocation}</td>
                                <td className="dark-tb-body-text">{executive.dutyDescription}</td>
                                <td>
                                    <button
                                        className="btn btn-sm btn-warning dark-tb-update-btn"
                                        onClick={() => handleUpdate(executive.id)}
                                    >
                                        <i className="fas fa-edit"></i>
                                    </button>
                                    <button
                                        className="btn btn-sm btn-danger dark-tb-delete-btn"
                                        onClick={() => handleDelete(executive.id)}
                                    >
                                        <i className="fas fa-trash"></i>
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            {/* Güncelleme Modal */}
            <Modal show={showUpdateModal} onHide={handleCloseUpdateModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Güncelleme İşlemi</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3">
                            <Form.Label>Adı Soyadı</Form.Label>
                            <Form.Control
                                type="text"
                                name="name"
                                value={updateFormData.name}
                                onChange={updateHandleInputChange}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Görev Yeri</Form.Label>
                            <Form.Control
                                type="text"
                                name="dutyLocation"
                                value={updateFormData.dutyLocation}
                                onChange={updateHandleInputChange}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Görev Tanımı</Form.Label>
                            <Form.Control
                                type="text"
                                name="dutyDescription"
                                value={updateFormData.dutyDescription}
                                onChange={updateHandleInputChange}
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseUpdateModal}>
                        Kapat
                    </Button>
                    <Button variant="primary" onClick={updateHandleSave}>
                        Güncellemeyi Kaydet
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Silme Modal */}
            <Modal show={showDeleteModal} onHide={handleCloseDeleteModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Silme İşlemi</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>ID: {selectedExecutiveId} silmek istediğinize emin misiniz?</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseDeleteModal}>
                        İptal
                    </Button>
                    <Button variant="danger" onClick={confirmDelete}>
                        Sil
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Add Data Modal */}
            <Modal show={showAddModal} onHide={handleCloseAddModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Yeni Üye Ekle</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3">
                            <Form.Label>Adı Soyadı</Form.Label>
                            <Form.Control
                                type="text"
                                name="name"
                                value={addFormData.name}
                                onChange={addHandleInputChange}
                                placeholder="Adı Soyadı Girin"
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Görev Yeri</Form.Label>
                            <Form.Control
                                type="text"
                                name="dutyLocation"
                                value={addFormData.dutyLocation}
                                onChange={addHandleInputChange}
                                placeholder="Görev Yeri Girin"
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Görev Tanımı</Form.Label>
                            <Form.Control
                                type="text"
                                name="dutyDescription"
                                value={addFormData.dutyDescription}
                                onChange={addHandleInputChange}
                                placeholder="Görev Tanımı Girin"
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseAddModal}>
                        Kapat
                    </Button>
                    <Button variant="primary" onClick={addHandleSave}>
                        Kaydet
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}
