import { useNavigate } from "react-router-dom";
import '../styles/ApplyExam.css';
import '../styles/Modal.css';
import InfoModal from '../layouts/modals/InfoModal'
import ConfirmationModalOneButton from '../layouts/modals/ConfirmationModalOneButton'
import React, { useState, useEffect, useRef } from 'react'
import TeamService from '../services/TeamService';
import NotificationService from '../services/NotificationService';
import { toast } from "react-toastify";


export default function ApplyExam() {

    const navigate = useNavigate();

    const teamService = new TeamService();
    const notificationService = new NotificationService();

    const [isButtonActive, setIsButtonActive] = useState(false);

    const [showModal, setShowModal] = useState(false);
    const [modalTitle, setModalTitle] = useState('');
    const [modalBody, setModalBody] = useState('');

    const [showNotificationModal, setShowNotificationModal] = useState(false);
    const [unReadNotifications, setUnReadNotifications] = useState([]);


    const [isAvaliableTeam, setIsAvaliableTeam] = useState(false);


    useEffect(() => {

        //takım kontrolü fonksiyonu
        const checkTeamInfo = () => {

            teamService.getTeamByUserId().then(result => {

                //console.log(result)
                if (result.data.success && result.data.data.length === 0) {

                    setIsAvaliableTeam(true)
                    setModalTitle("⚠️UYARI !")
                    setModalBody("Başvurunuzu tamamlamak için lütfen Takım Kayıt sayfası üzerinden takımınızı oluşturunuz, aksi takdirde başvurunuz geçersiz sayılacak.")
                    setShowModal(true);

                }

            }).catch(error => {
                const fullMessage = error.response.data.data.message;
                const match = fullMessage.match(/:\s*(.*)/); // ":" karakterinden sonra gelen kısmı yakala
                const message = match ? match[1] : fullMessage; // Eğer bir eşleşme varsa al, yoksa tüm mesajı kullan
                toast.error(message);
            })
        }

        // Tarih kontrol fonksiyonu
        const checkDate = () => {
            const now = new Date();
            //console.log("şu anki tarih")
            //console.log(now)

            // Başlangıç ve bitiş tarihleri
            const startDate = new Date('2024-11-20T10:00:00'); // Başlangıç tarihi
            const endDate = new Date('2024-11-20T12:00:00'); // Bitiş tarihi

            // Eğer şu anki tarih bu aralıktaysa butonu aktif yap
            if (now >= startDate && now <= endDate) {
                setIsButtonActive(true);
            } else {
                setIsButtonActive(false);
            }
        };

        // İlk yükleme anında kontrol
        checkTeamInfo()


        // İlk yükleme anında kontrol
        checkDate();

        // Dakikada bir (60 saniye) kontrol edecek interval ayarla
        const intervalId = setInterval(checkDate, 60000);

        // Bileşen unmount olduğunda interval'ı temizle
        return () => clearInterval(intervalId);

    }, []);


    // get notification read status
    useEffect(() => {

        notificationService.getNotificationReadStatus().then(result => {

            //console.log(result)
            if (result.data.success && result.data.data.length !== 0) {

                setUnReadNotifications(result.data.data)

                setModalTitle("⚠️UYARI !")
                setModalBody("Yeni bir bildirim yayınlanmıştır. Okumak için bildirimler sayfasını ziyaret ediniz.")
                setShowNotificationModal(true);
            }

        }).catch(error => {
            toast.error("Bildirim read statu bilgilerini çekerken bir sorun oluştu!");
        })

    }, []);

    // bildirim modalı kapat
    const handleCloseNotificationModal = () => {

        setShowNotificationModal(false);
        setModalTitle("");
        setModalBody("")
    };

    const handleConfirmNotificationModal = () => {
        navigate("/home/your-notifications");
    }


    const handleApplyExam = () => {

        if (isButtonActive) {
            navigate("/home/start-exam");
        } else {

            setModalTitle("⚠️UYARI !")
            setModalBody("Sınav dönemi şu anda aktif değil. Sınav sistemi 20.11.2024 tarihinde, 10.00 - 12.00 saatleri arasında aktif olacaktır. Gelişmeler için lütfen Duyurular sayfasını ziyaret ediniz.")
            setShowModal(true);
        }
    };

    // duyuru modalı kapat
    const handleCloseModal = () => {

        if (isButtonActive === false) {
            setShowModal(false);
            setModalTitle("");
            setModalBody("")
        }

        if (isAvaliableTeam === true) {
            setShowModal(false);
            setModalTitle("");
            setModalBody("")
            localStorage.setItem("savedUserId", sessionStorage.getItem('userId'));
            sessionStorage.clear(); // Oturum bilgilerini temizle
            navigate("/home/teamRegister");
        }
    };

    return (

        <div className='apply-exam-container'>
            <div className="user-name-div">
                <p>Merhaba, </p>
                <p>{sessionStorage.getItem('firstName') + " "} {sessionStorage.getItem('lastName')}   </p>
            </div>

            <div className='exam-intro-div'>
                <p>Yarışmaya Hoş Geldiniz! </p>
                <p>Teknolojinin sınırlarını zorlayacak yeteneklerinizi sergilemeye hazır mısınız? <br /> Bu platformda yeteneklerinizi
                    gösterecek, yeni deneyimler kazanacak ve heyecan dolu <br /> bir yarışmanın parçası olacaksınız. Başarılar dileriz!
                </p>
            </div>

            <div className='apply-exam-btn' onClick={handleApplyExam}>
                <p>SINAVA BAŞLA </p>
            </div>

            <div class="exam-description-div">

                <p className='exam-description-title'>Yarışmaya Katılım Süreci</p>

                <p><span>1. Sunum Yükleme:</span> Sınav dönemi aktif olduğunda 'Sınava Başla' butonuna tıklayarak Sunum Yükleme ve Sınav Sürecine yönlendirileceksiniz.
                    Sınav Kuralları ve Katılım Şartları'nı onayladıktan sonra ilk olarak sunumunuzu sisteme yüklemeniz gerekir.
                    Sınava başlamadan önce hazırladığınız sunumu, zip formatında sistemimize yüklemeniz gerekecek. Zip formatındaki sunumunuzun boyutu maksimum 40 MB olmalıdır. Sunumunuz, projenizin tüm detaylarını içermeli ve yarışmamızda sizi en iyi şekilde temsil etmelidir.</p>


                <p><span>2. Açık Uçlu Sorular:</span> İlk etapta sizlere sunacağımız 10 adet açık uçlu soruyu dikkatlice cevaplamanızı bekliyoruz. Bu sorular, projelerinize ve bakış açınıza dair önemli bilgiler edinmenize yardımcı olacak. Lütfen yanıtlarınızı mümkün olduğunca detaylı ve anlaşılır bir şekilde yazmaya özen gösterin.</p>


                <p><span>3. Başvuru Tamamla:</span> Soruları yanıtladıktan sonra "Sınavı Tamamla" butonuna tıklayarak katılım sürecinizi tamamlayabilirsiniz. Bu adımda herhangi bir eksiklik olmaması için bilgilerinizi kontrol etmeyi unutmayın!​  </p>

                <span>Başarılar dileriz!</span>


            </div>

            {showModal && <InfoModal
                show={showModal}
                handleClose={handleCloseModal}
                title={modalTitle}
                body={modalBody}
            />}

            {showNotificationModal &&
                <div className="my-modal">
                    <div className="my-modal-content">
                        <button className="my-modal-close-button" onClick={handleCloseNotificationModal}>X</button>
                        <div className="my-modal-body">
                            <p>{modalTitle}</p>
                            <p>{modalBody}</p>
                            <p className="my-modal-body-notification-title">{unReadNotifications.at(-1).title}</p>
                        </div>
                        <div className="my-modal-footer">
                            <button className="btn my-modal-confirm-notification-button" onClick={handleConfirmNotificationModal}>Bildirimler Sayfasına Git</button>
                        </div>
                    </div>
                </div>
            }

        </div>
    )
}
