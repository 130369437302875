import axios from "axios"
import HostAndPortInfo from "../enums/EnHostandPortInfo";

const baseURL = `${HostAndPortInfo.PROTOCOL}://${HostAndPortInfo.HOSTNAME}:${HostAndPortInfo.PORT}`;

export default class AnnouncementService {  

  getAllAnnouncement() {
    return axios({
      method: 'get',
      maxBodyLength: Infinity,
      url: `${baseURL}/api/announcements`,
    })
  }

  getLastThreeAnnouncements() {
    return axios({
      method: 'get',
      maxBodyLength: Infinity,
      url: `${baseURL}/api/announcements/last-three`,
    })
  }

  getAllAnnouncementByDate(date) {
    return axios({
      method: 'get',
      maxBodyLength: Infinity,
      url: `${baseURL}/api/announcements/with-date/${date}`,
    })
  }


  addAnnouncement(data) {

    return axios({
      method: 'post',
      maxBodyLength: Infinity,
      url: `${baseURL}/api/announcements`,
      data: data,
      headers: {
        'Content-Type': 'multipart/form-data', // FormData gönderdiğimi belirtmek için
        'Authorization': 'Bearer '  + sessionStorage.getItem('token') 
      }

    })
  }

  updateAnnouncement(id,data) {

    return axios({
      method: 'put',
      maxBodyLength: Infinity,
      url: `${baseURL}/api/announcements/${id}`,
      data: data,
      headers: {
        'Content-Type': 'multipart/form-data', // FormData gönderdiğimi belirtmek için
        'Authorization': 'Bearer '  + sessionStorage.getItem('token') 
      }

    })
  }

  deleteAnnouncement(id) {

    return axios({
      method: 'delete',
      maxBodyLength: Infinity,
      url: `${baseURL}/api/announcements/${id}`,      
      headers: {
        'Authorization': 'Bearer '  + sessionStorage.getItem('token') 
      }

    })
  }

  downloadFile(anncountId,fileName) {
    return axios({
        method: 'get',
        responseType: 'blob' ,// Blob olarak veri alıyoruz
        maxBodyLength: Infinity,
        url: `${baseURL}/api/announcements/download/${anncountId}?filename=${fileName}`,
       
    })
}

}