import React, { useState, useEffect } from 'react'
import '../../styles/DarkTable.css';
import { Modal, Button, Form } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import InstitutionService from '../../services/InstitutionService';
import { toast } from "react-toastify";
import * as XLSX from "xlsx";


export default function ManageInstitution() {

    const institutionService = new InstitutionService();

    const [refreshData, setRefreshData] = useState(false);

    const [showUpdateModal, setShowUpdateModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showAddModal, setShowAddModal] = useState(false);


    const [selectedInstitutionId, setSelectedInstitutionId] = useState(null);

    const [addFormData, setAddFormData] = useState({
        name: '',
        code: '',
        city: '',
        district: ''
    });

    const [updateFormData, setUpdateFormData] = useState({
        id: '',
        name: '',
        code: '',
        city: '',
        district: ''
    });


    const [institutions, setInstitutions] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");

    useEffect(() => {

        institutionService.getAllInstitutions().then(result => {


            if (result.data.success) {
                setInstitutions(result.data.data);
            }

        }).catch(error => {
            toast.error("Kurumlar listelenirken bir sorun oluştu!");
        })



    }, [refreshData]);

    // Arama terimine göre filtreleme
    const filteredInstitution = institutions.filter((institution) =>
        `${institution.name}`.toLowerCase().includes(searchTerm.toLowerCase()) ||
        `${institution.code}`.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const handleAdd = () => {
        // Add Data modalını açma
        setShowAddModal(true);
    };

    const handleCloseAddModal = () => {
        // Add Data modalını kapama
        setShowAddModal(false);
        setAddFormData({ name: '', code: '', city: '', district: '' });
    };


    const addHandleInputChange = (e) => {
        // Form verilerini güncelleme
        const { name, value } = e.target;
        setAddFormData(prevState => ({
            ...prevState,
            [name]: value.toUpperCase()
        }));
    };


    const addHandleSave = () => {

        institutionService.saveInstitution(addFormData).then(result => {

            if (result.data.success) {
                toast.success("Kurum kaydedildi.")
                setRefreshData(prev => !prev);
                setAddFormData({ name: '', code: '', city: '', district: '' });

                setShowAddModal(false);

            }
        }).catch(error => {
            const fullMessage = error.response.data.data.message;
            const match = fullMessage.match(/:\s*(.*)/); // ":" karakterinden sonra gelen kısmı yakala
            const message = match ? match[1] : fullMessage; // Eğer bir eşleşme varsa al, yoksa tüm mesajı kullan
            toast.error(message);
        })

    };

    const handleUpdate = (id) => {
        // Güncelleme modalını açma

        const selectedItem = institutions.find(institution => institution.id === id);

        setSelectedInstitutionId(selectedItem.id);
        setUpdateFormData({
            id: selectedItem.id,
            name: selectedItem.name,
            code: selectedItem.code,
            city: selectedItem.city,
            district: selectedItem.district
        });

        setShowUpdateModal(true);
    };


    const handleCloseUpdateModal = () => {
        // Güncelleme modalını kapama

        setShowUpdateModal(false);
        setSelectedInstitutionId(null);
        setUpdateFormData({ id: '', name: '', code: '', city: '', district: '' });
    };

    const updateHandleInputChange = (e) => {
        // Form verilerini güncelleme

        const { name, value } = e.target;
        setUpdateFormData(prevState => ({
            ...prevState,
            [name]: value.toUpperCase()
        }));
    };


    const updateHandleSave = () => {
        // Formu kaydetme

        //console.log('Güncellenen veriler:', updateFormData);

        institutionService.updateInstitution(selectedInstitutionId, updateFormData).then(result => {

            if (result.data.success) {
                toast.success("Kurum güncellendi.")
                setRefreshData(prev => !prev);
                setUpdateFormData({ id: '', name: '', code: '', city: '', district: '' });

                setShowUpdateModal(false);

            }
        }).catch(error => {
            const fullMessage = error.response.data.data.message;
            const match = fullMessage.match(/:\s*(.*)/); // ":" karakterinden sonra gelen kısmı yakala
            const message = match ? match[1] : fullMessage; // Eğer bir eşleşme varsa al, yoksa tüm mesajı kullan
            toast.error(message);
        })
    };


    const handleDelete = (id) => {
        // Silme modalını açma

        setSelectedInstitutionId(id);
        setShowDeleteModal(true);
    };


    const handleCloseDeleteModal = () => {
        // Silme modalını kapama
        setShowDeleteModal(false);
        setSelectedInstitutionId(null);
    };


    const confirmDelete = () => {
        // Silme işlemi

        institutionService.deleteInstitution(selectedInstitutionId).then(result => {

            //console.log(result)
            if (result.status === 200) {
                toast.success("Kurum silindi.");
                setRefreshData(prev => !prev);
            }

        }).catch(error => {
            toast.error("Kurum silinirken bir hata oluştu!");
            const fullMessage = error.response.data.data.message;
            const match = fullMessage.match(/:\s*(.*)/); // ":" karakterinden sonra gelen kısmı yakala
            const message = match ? match[1] : fullMessage; // Eğer bir eşleşme varsa al, yoksa tüm mesajı kullan
            toast.error(message);
        })

        setShowDeleteModal(false);
    };

    const handleExportToExcel = () => {

        const excelData = institutions
            .filter((institution) => institution.city !== "İSTANBUL")
            .map((institution, index) => ({
                "Sıra": index + 1,
                "Kurum Kodu": institution.code,
                "Kurum Adı": `${institution.name}`,
                "İl": institution.city,
                "İlçe": institution.district
            }));

        // Yeni bir çalışma kitabı (Excel sayfası) oluşturun
        const worksheet = XLSX.utils.json_to_sheet(excelData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "AZTY Kayıtlı Kurumlar");

        // Excel dosyasını indirin
        XLSX.writeFile(workbook, "AZTY_Institutions.xlsx");
    };

    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 20; // Sayfada gösterilecek öğe sayısı

    // Sayfalama işlemi
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredInstitution.slice(indexOfFirstItem, indexOfLastItem);

    const handleNextPage = () => {
        if (currentPage < Math.ceil(filteredInstitution.length / itemsPerPage)) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    return (
        <div className="dark-tb-main">
            <div className="dark-tb-container text-light">
                <table className="table table-dark table-bordered mt-3">
                    <thead>
                        <tr>
                            <th className="dark-bg-color tb-header-text" scope="col" colSpan="6">Kurumları Yönet</th>
                        </tr>
                        <tr>
                            <th className="dark-bg-color" scope="col" colSpan="6">
                                <div className="search-container">

                                    <input

                                        type="text"
                                        placeholder="Kurum ismi veya kurum kodu ara..."
                                        className="search-input"
                                        value={searchTerm}
                                        onChange={(e) => setSearchTerm(e.target.value)}
                                    />

                                    <div className="icons-container">

                                        <Button className="dark-tb-add-btn" variant="success" onClick={handleAdd}>
                                            <i className="fas fa-plus"> </i>
                                        </Button>

                                        <Button className="dark-tb-add-btn" variant="primary" onClick={handleExportToExcel}>
                                            <i className="fas fa-file-excel"></i>
                                        </Button>
                                    </div>

                                </div>


                            </th>


                        </tr>
                        <tr>
                            <th className="dark-bg-color" scope="col">ID</th>
                            <th className="dark-bg-color dark-tb-body-text" scope="col">KURUM KODU</th>
                            <th className="dark-bg-color dark-tb-body-text" scope="col">KURUM</th>
                            <th className="dark-bg-color dark-tb-body-text">İL</th>
                            <th className="dark-bg-color dark-tb-body-text">İLÇE</th>
                            <th className="dark-bg-color dark-tb-body-text">İŞLEMLER</th>

                        </tr>
                    </thead>
                    <tbody>
                        {currentItems.map((institution, index) => (
                            <tr key={institution.id}>
                                <th className="dark-bg-color dark-tb-body-text" scope="row">{institution.id}</th>
                                <td className="dark-tb-body-text">{institution.code}</td>
                                <td className="dark-tb-body-text">{institution.name.toUpperCase()}</td>
                                <td className="dark-tb-body-text">{institution.city.toUpperCase()}</td>
                                <td className="dark-tb-body-text">{institution.district.toUpperCase()}</td>

                                <td>

                                    <button
                                        className="btn btn-m btn-warning dark-tb-update-btn"
                                        onClick={() => handleUpdate(institution.id)}
                                    >
                                        <i className="fas fa-edit"></i>
                                    </button>

                                    <button
                                        className="btn btn-m btn-danger dark-tb-delete-btn"
                                        onClick={() => handleDelete(institution.id)}
                                    >
                                        <i className="fas fa-trash"></i>
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>

                {/* Sayfalama kontrolü */}
                <div className="institution-pagination">
                    <button onClick={handlePreviousPage} disabled={currentPage === 1}>Önceki</button>
                    <span>Sayfa {currentPage} / {Math.ceil(filteredInstitution.length / itemsPerPage)}</span>
                    <button onClick={handleNextPage} disabled={currentPage === Math.ceil(filteredInstitution.length / itemsPerPage)}>Sonraki</button>
                </div>

            </div>

            {/* Add Data Modal */}
            <Modal show={showAddModal} onHide={handleCloseAddModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Yeni Kurum Ekle</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3">
                            <Form.Label>Kurum Kodu</Form.Label>
                            <Form.Control
                                type="text"
                                name="code"
                                value={addFormData.code}
                                onChange={addHandleInputChange}
                                placeholder="Kurum Kodu Girin"
                            />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Kurum Adı</Form.Label>
                            <Form.Control
                                type="text"
                                name="name"
                                value={addFormData.name}
                                onChange={addHandleInputChange}
                                placeholder="Kurum Adı Girin"
                            />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>İl </Form.Label>
                            <Form.Control
                                type="text"
                                name="city"
                                value={addFormData.city}
                                onChange={addHandleInputChange}
                                placeholder="İl Girin"
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>İlçe</Form.Label>
                            <Form.Control
                                type="text"
                                name="district"
                                value={addFormData.district}
                                onChange={addHandleInputChange}
                                placeholder="İlçe Girin"
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseAddModal}>
                        Kapat
                    </Button>
                    <Button variant="success" onClick={addHandleSave}>
                        Kaydet
                    </Button>
                </Modal.Footer>
            </Modal>



            {/* Güncelleme Modal */}
            <Modal show={showUpdateModal} onHide={handleCloseUpdateModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Kurum Güncelle</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <Form>
                        <Form.Group className="mb-3">
                            <Form.Label>Kurum Kodu</Form.Label>
                            <Form.Control
                                type="text"
                                name="code"
                                value={updateFormData.code}
                                onChange={updateHandleInputChange}
                            />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Kurum Adı</Form.Label>
                            <Form.Control
                                type="text"
                                name="name"
                                value={updateFormData.name}
                                onChange={updateHandleInputChange}
                            />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>İl </Form.Label>
                            <Form.Control
                                type="text"
                                name="city"
                                value={updateFormData.city}
                                onChange={updateHandleInputChange}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>İlçe</Form.Label>
                            <Form.Control
                                type="text"
                                name="district"
                                value={updateFormData.district}
                                onChange={updateHandleInputChange}
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseUpdateModal}>
                        Kapat
                    </Button>
                    <Button variant="success" onClick={updateHandleSave}>
                        Güncellemeyi Kaydet
                    </Button>
                </Modal.Footer>
            </Modal>


            {/* Silme Modal */}
            <Modal show={showDeleteModal} onHide={handleCloseDeleteModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Silme İşlemi</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p> {selectedInstitutionId} ID'ye sahip kurumu silmek istediğinize emin misiniz?</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseDeleteModal}>
                        İptal
                    </Button>
                    <Button variant="danger" onClick={confirmDelete}>
                        Sil
                    </Button>
                </Modal.Footer>
            </Modal>

        </div>
    )
}
