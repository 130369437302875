import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../../styles/ArchiveDetail2023.css';

export default function ArchiveDetail2023({ clickedButton }) {

  const [activeButton, setActiveButton] = useState(clickedButton ||'goruntuButton');
  const [visibleAllResults, setVisibleAllResults] = useState(false);

  //console.log(clickedButton)
  const getButtonClass = (buttonName) => {
    return buttonName === activeButton ? 'menu-btn-2023 active' : 'menu-btn-2023';
  };

  return (
    <div className="archive-2023">

      <div className="roboRally-2023" >
        <p className="roboRally-text-2023">2023 Labirent Yarışması</p>
      </div>


      <div className="menu-btn-div-2023" >

        <button type="button" className={getButtonClass('goruntuButton')} onClick={() => { setActiveButton('goruntuButton'); setVisibleAllResults(false) }} >
          Yarışma Görüntüleri
        </button>

        <button type="button" className={getButtonClass('dereceButton')} onClick={() => { setActiveButton('dereceButton'); setVisibleAllResults(false) }}>
          Dereceler
        </button>

      </div>


      {activeButton === "goruntuButton" && (<>

        <div className="my-container-archive-2023">

          <div className="archive-2023-goruntu-container">
            <div>
              <a href="https://www.youtube.com/watch?v=G1sQ6vD_CnU" target="_blank" rel="noopener noreferrer">
                <img className="youtube-img-2023" alt="youtube link" src={`${process.env.PUBLIC_URL}/images/archive/2023/youtube-first-2023.png`} />
              </a>
              <p>Ağrı'nın Zirvesinde Teknoloji Yarışması 2023 | <br /> Atmaca Holding</p>
            </div>

            <div>
              <a href="https://www.youtube.com/watch?v=52sqjWU_EsE" target="_blank" rel="noopener noreferrer">
                <img className="youtube-img-2023" alt="youtube link" src={`${process.env.PUBLIC_URL}/images/archive/2023/youtube-second-2023.png`} />
              </a>

              <p>Ağrı'nın Zirvesinde Heyecan Dolu Robot Yarışması ! | <br /> Emek, Heyecan ve Azim Dolu Bir Zafer Yolculuğu</p>
            </div>

          </div>


        </div>


        <div className="archive-2023-goruntu-alt-container">
          <div className='first-goruntu-div'>

            <img alt="first-img-2023" src={`${process.env.PUBLIC_URL}/images/archive/2023/goruntu-first-2023.png`} />
            <img alt="second-img-2023" src={`${process.env.PUBLIC_URL}/images/archive/2023/goruntu-second-2023.png`} />

          </div>
          <div className='second-goruntu-div'>

            <img alt="third-img-2023" src={`${process.env.PUBLIC_URL}/images/archive/2023/goruntu-third-2023.png`} />
            <img alt="fourt-img-2023" src={`${process.env.PUBLIC_URL}/images/archive/2023/goruntu-fourth-2023.png`} />

          </div>
        </div></>
      )}

      {activeButton === "dereceButton" && visibleAllResults === false && (<>

        <div className="my-container-archive-2023">


          <img className="derecekazananlar2023" alt="derece 2023 kazananlar" src={`${process.env.PUBLIC_URL}/images/archive/2023/derece-2023-kazananlar.png`} />

          <button type="button" className="menu-btn-2023" style={{ filter: "blur(0)", marginBottom: "2rem" }} onClick={() => setVisibleAllResults(true)}>
            Tüm Sonuçlar
          </button>


        </div>
      </>
      )}

      {activeButton === "dereceButton" && visibleAllResults === true && (
        
        
        <div className="my-container-archive-2023">

          <img className="yarisanlar2023" alt="yarisanlar 2023" src={`${process.env.PUBLIC_URL}/images/archive/2023/derece-2023.png`} />

        </div>
        )



      }

    </div>
  )
}
